import React from "react";
import { Route, Routes } from "react-router-dom";
import Dashboard from "./pages/Dashboard";
import Login from "./pages/login/Login";
import SignUp from "./pages/login/SignUp";
import Welcome from "./pages/Welcome";
import PlanRoutes from "./PlanRoutes";

const StrategyPlanningRoutes = () => {
  // console.log(pathname.split("/").pop());
  return (
    <>
      <Routes>
        <Route path="/" element={<Welcome />} />
        <Route path=":planId/*" element={<PlanRoutes />} />
        <Route path="dashboard" element={<Dashboard />} />
        <Route path="login" element={<Login />} />
        <Route path="signUp" element={<SignUp />} />
      </Routes>
    </>
  );
};

export default StrategyPlanningRoutes;
