import FormTextInput from "@Common/components/react-hook-form-ui/FormTextInput";
import { visionValidation } from "@Strategy-planning/data/validations";
import FormContainer from "@Strategy-planning/layouts/FormContainer";
import StepperLayout from "@Strategy-planning/layouts/StepperLayout/StepperLayout";
import TipsVisionContent from "@Strategy-planning/pages/Plan/tipsLayout/TipsVisionContent";
import React from "react";
import { Helmet } from "react-helmet-async";

const formData = {
  validationSchema: visionValidation,
};

const detailsData = {
  title: "Your vision statement",
  details:
    "The anchor-point of your organization. What you want to achieve in the mid-to-long term.",
  endDivider: false,
  tipsTitle: "Vision",
  tipsContent: <TipsVisionContent />,
};

const Vision = ({ initialValues }) => {
  return (
    <div>
      <Helmet>
        <meta
          name="description"
          content="Vision page for strategy planning tool"
        />
      </Helmet>
      <FormContainer
        initialValues={initialValues}
        validationSchema={formData.validationSchema}
      >
        <StepperLayout
          detailsData={detailsData}
          submitButtonJustify="flex-start"
          maxWidth="sm"
        >
          <FormTextInput
            name="vision"
            placeholder="Everyone who wants people-powered transport is getting on a Meerkat Cycle"
            fullWidth
            multiline
            minRows={3}
            maxRows={5}
          />
        </StepperLayout>
      </FormContainer>
    </div>
  );
};

export default Vision;
