import {
  Button,
  Container,
  Divider,
  Grid,
  Stack,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import React from "react";
import { ReactComponent as CascadeTools } from "@Common/assets/cascade-tools.svg";
import { useLocation, useNavigate } from "react-router-dom";
import { storeLastLocation } from "@Strategy-planning/storage/lastLocation";
import { getUserToken, storeUserToken } from "@Common/storage/authTokenStorage";
import {
  getAnonymousToken,
  storeAnonymousToken,
} from "@Common/storage/anonymousTokenStorage";
import { addDoc, collection } from "firebase/firestore";
import LoadingElement from "@Common/components/LoadingElement";
import { db } from "@Common/firebase";
import { defaultInitialValues } from "@Strategy-planning/data/defaults";
import { PATH_STRATEGY_PLANNING } from "@Common/tokens/routeTokens";

const styles = {
  height: "100vh",
};

const Welcome = () => {
  const { pathname } = useLocation();
  React.useEffect(() => {
    document.title = "Welcome - strategy planning tool";
  }, []);
  const navigate = useNavigate();
  const [loading, setLoading] = React.useState(false);

  React.useEffect(() => {
    storeLastLocation(pathname);
    const userToken = getUserToken();
    const anonymousToken = getAnonymousToken();
    if (userToken) {
      setLoading("Redirecting to dashboard");
      navigate(`/${PATH_STRATEGY_PLANNING}/dashboard`, { replace: true });
    }
    if (anonymousToken) {
      setLoading("Redirecting to plan");
      navigate(`/${PATH_STRATEGY_PLANNING}/${anonymousToken}/details`, {
        replace: true,
      });
    }
    setLoading(false);
  }, [navigate, pathname]);

  const handleNewPlan = () => {
    setLoading("Creating new plan");
    const planCollectionRef = collection(db, "plan");
    addDoc(planCollectionRef, defaultInitialValues).then((newPlan) => {
      storeUserToken(null);
      storeAnonymousToken(newPlan.id);
      setLoading(false);
      navigate(`/${PATH_STRATEGY_PLANNING}/${newPlan.id}/details`);
    });
  };

  if (loading) return <LoadingElement>{loading}</LoadingElement>;
  return (
    <>
      <Box sx={styles}>
        <Grid
          container
          direction="column"
          sx={{ minHeight: "100vh" }}
          flexWrap="wrap"
        >
          <Grid item>
            <Grid
              container
              // justifyContent="flex-end"
              alignItems="center"
              sx={{
                m: 2,
                p: 1,
                pr: 3,
                justifyContent: {
                  xs: "center",
                  // sm: "space-between",
                  sm: "flex-end",
                },
              }}
            >
              <Button
                variant="text"
                color="eggplant"
                sx={{ mr: 2 }}
                onClick={() => navigate(`/${PATH_STRATEGY_PLANNING}/login`)}
              >
                Login
              </Button>
              <Button
                variant="outlined"
                color="eggplant"
                sx={{ mr: 2 }}
                onClick={() => navigate(`/${PATH_STRATEGY_PLANNING}/signUp`)}
              >
                Try cascade for free
              </Button>
            </Grid>
          </Grid>
          <Grid item>
            <Stack
              direction={{ xs: "column", sm: "row" }}
              justifyContent="center"
              alignItems="center"
              spacing={{ xs: 0.5, sm: 3 }}
              divider={<Divider orientation="vertical" flexItem />}
              mt={{ xs: 2 }}
            >
              <CascadeTools />
              <Box sx={{ display: { xs: "none", sm: "block" } }}>
                <Typography
                  variant="h5"
                  sx={{
                    fontWeight: 700,
                    mt: 0,
                  }}
                >
                  Strategic Plan
                </Typography>
              </Box>
            </Stack>
          </Grid>
          <Grid item sx={{ flexGrow: 1 }}></Grid>
          <Grid item sx={{ mt: 10, mb: 10 }}>
            <Container maxWidth="sm">
              <Stack spacing={2} justifyContent="flex-end">
                <Typography variant="h6" color="secondary" textAlign="center">
                  Free strategy tool
                </Typography>
                <Typography
                  variant="h2"
                  color="primary.eggplant"
                  textAlign="center"
                  sx={{ fontWeight: 600, fontSize: { xs: 40, sm: 60 } }}
                >
                  Create Your Strategic Plan
                </Typography>
              </Stack>
              <Grid container justifyContent="center" sx={{ mt: 6 }}>
                <Button onClick={handleNewPlan}>Get Started</Button>
              </Grid>
            </Container>
          </Grid>
          <Grid item sx={{ flexGrow: 1 }}></Grid>
          <Grid
            // container
            item
            sx={{ m: 2, height: "136.5px" }}
            // alignItems="flex-end"
          >
            <Container maxWidth="sm">
              <Typography variant="body1" color="secondary" textAlign="center">
                This is a free strategic planing tool, based on thousands of
                successful strategy implementations.
              </Typography>
            </Container>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default Welcome;
