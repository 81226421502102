import React, { useState } from "react";
import DayPicker, { DateUtils } from "react-day-picker";
import "./calendar.css";

import { addMonths, differenceInCalendarMonths, set, setDate } from "date-fns";
import YearMonthForm from "./YearMonthForm";
import NavbarForm from "./NavbarForm";

const DateRangePicker = ({ from, to, setFrom, setTo }) => {
  const currentYear = new Date().getFullYear();
  const currentMonth = new Date().getMonth();

  const [enteredTo, setEnteredTo] = useState(to);
  const [restrict, setRestrict] = useState({
    from: from && setDate(from, 1),
    to: from && set(from, { month: from.getMonth() + 1, date: 1 }),
  });
  const modifiers = { start: from, end: enteredTo || to };
  const selectedDays = [from, { from, to: enteredTo || to }];

  const [fromMonthYearSelect, setFromMonthYearSelect] = useState(
    from ? from : new Date(currentYear, currentMonth)
  );
  const [toMonthYearSelect, setToMonthYearSelect] = useState(
    to ? to : new Date(currentYear, currentMonth + 1)
  );

  React.useEffect(() => {
    if (from && !to) {
      const month = from.getMonth();
      const year = from.getFullYear();
      setFromMonthYearSelect(new Date(year, month));
      setToMonthYearSelect(new Date(year, month + 1));
      setRestrict({
        from: new Date(year, month),
        to: new Date(year, month + 1),
      });
    }
    if (!to && !from) {
      setRestrict({ from: null, to: null });
    }
    if (from && to) {
      if (differenceInCalendarMonths(from, to) === 0) {
        setToMonthYearSelect(addMonths(from, 1));
      }
    }
  }, [from, to]);

  // static defaultProps = {
  //   numberOfMonths: 2
  // };

  const isSelectingFirstDay = (from, to, day) => {
    const isBeforeFirstDay = from && DateUtils.isDayBefore(day, from);
    const isRangeSelected = from && to;
    return !from || isBeforeFirstDay || isRangeSelected;
  };

  const handleDayClick = (day) => {
    if (from && to && day >= from && day <= to) {
      handleResetClick();
      return;
    }
    if (isSelectingFirstDay(from, to, day)) {
      setFrom(day);
      setTo(null);
      setEnteredTo(null);
    } else {
      setTo(day);
      setEnteredTo(day);
    }
  };

  const handleDayMouseEnter = (day) => {
    if (!isSelectingFirstDay(from, to, day)) {
      setEnteredTo(day);
    }
  };

  const handleResetClick = () => {
    setFrom(null);
    setTo(null);
    setEnteredTo(null);
  };

  return (
    <div className="RangeExample">
      <DayPicker
        className="Range"
        month={fromMonthYearSelect}
        toMonth={restrict.from}
        selectedDays={selectedDays}
        modifiers={modifiers}
        onDayClick={handleDayClick}
        onDayMouseEnter={handleDayMouseEnter}
        captionElement={({ date, localeUtils }) => (
          <YearMonthForm
            date={date}
            localeUtils={localeUtils}
            onChange={setFromMonthYearSelect}
            toInit={from}
          />
        )}
        navbarElement={<NavbarForm disableTo={from} />}
      />
      <DayPicker
        className="Range"
        month={toMonthYearSelect}
        fromMonth={restrict.to}
        selectedDays={selectedDays}
        modifiers={modifiers}
        onDayClick={handleDayClick}
        onDayMouseEnter={handleDayMouseEnter}
        captionElement={({ date, localeUtils }) => (
          <YearMonthForm
            date={date}
            localeUtils={localeUtils}
            onChange={setToMonthYearSelect}
            fromInit={from}
          />
        )}
        navbarElement={<NavbarForm disableFrom={from} />}
      />
    </div>
  );
};

export default DateRangePicker;
