import {
  Avatar,
  Button,
  Container,
  Grid,
  Link,
  Typography,
} from "@mui/material";
import React from "react";
import { Helmet } from "react-helmet-async";

import {
  signInWithEmailAndPassword,
  createUserWithEmailAndPassword,
} from "firebase/auth";
import "./login.css";

import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import * as Yup from "yup";
import { useNavigate, Link as RouterLink } from "react-router-dom";

import { doc, getDoc, setDoc } from "firebase/firestore";

import { useSnackbar } from "notistack";
import { auth, db, signInWithGoogle } from "@Common/firebase";
import { getUserToken, storeUserToken } from "@Common/storage/authTokenStorage";
import {
  getAnonymousToken,
  storeAnonymousToken,
} from "@Common/storage/anonymousTokenStorage";
import { getLastLocation } from "@Strategy-planning/storage/lastLocation";
import {
  detailsValidation,
  focusAreaValidation,
  kpiValidation,
  objectiveValidation,
  projectValidation,
  validateAll,
  valuesValidation,
  visionValidation,
} from "@Strategy-planning/data/validations";
import LoadingElement from "@Common/components/LoadingElement";
import FormTextInput from "@Common/components/react-hook-form-ui/FormTextInput";
import { FormProvider, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { SPT_PATH_ORDER } from "@Strategy-planning/tokens/strategyPlanningPaths";
import { PATH_STRATEGY_PLANNING } from "@Common/tokens/routeTokens";

const sleep = (time) => new Promise((acc) => setTimeout(acc, time));

const SignUp = () => {
  React.useEffect(() => {
    document.title = "Signup";
  }, []);
  const { enqueueSnackbar } = useSnackbar();
  console.log("login page", getUserToken(), getAnonymousToken());
  // const regexString = `^(?!${error.password || ""}$).*$`;
  // const re = new RegExp(regexString);
  const validate = Yup.object({
    email: Yup.string().email("Email is invalid").required("Email is required"),
    password: Yup.string()
      .min(6, "Password must be at least 6 charaters")
      .required("Password is required"),
    confirmPassword: Yup.string()
      .oneOf([Yup.ref("password"), null], "Password must match")
      .required("Confirm password is required"),
  });
  // console.log(passwordConfig);
  const [submitting, setSubmitting] = React.useState(false);

  const navigate = useNavigate();

  const handleLogin = async (email, password) => {
    setSubmitting(true);
    try {
      const signInResult = await createUserWithEmailAndPassword(
        auth,
        email,
        password
      );
      await handleDataForLogin(signInResult);
      storeUserToken(signInResult.user.uid);
      storeAnonymousToken(null);
      setSubmitting(false);
      navigate(getLastLocation());
    } catch (error) {
      console.log("email and password login error", error);
      const errorCode = error.code;
      if (errorCode === "auth/email-already-in-use") {
        enqueueSnackbar("User already exists!", { variant: "info" });
        await sleep(500);
        enqueueSnackbar("Logging in user");
        try {
          const signInResult = await signInWithEmailAndPassword(
            auth,
            email,
            password
          );
          await handleDataForLogin(signInResult);
          storeUserToken(signInResult.user.uid);
          storeAnonymousToken(null);
          setSubmitting(false);
          navigate(getLastLocation());
        } catch (error) {
          console.log(error.code);
          if (error.code === "auth/wrong-password") {
            await sleep(500);
            enqueueSnackbar("Password is wrong!", { variant: "error" });
            navigate(`/${PATH_STRATEGY_PLANNING}/login`, {
              state: { email, password, error: "Incorrect Password" },
              replace: true,
            });
          } else {
            enqueueSnackbar(error.code, { variant: "error" });
          }
        }
      } else {
        enqueueSnackbar(errorCode, { variant: "error" });
      }
    }
    setSubmitting(false);
  };

  const handleGoogle = async () => {
    setSubmitting(true);
    try {
      const signInResult = await signInWithGoogle();
      await handleDataForLogin(signInResult);
      storeUserToken(signInResult.user.uid);
      storeAnonymousToken(null);

      navigate(getLastLocation());
    } catch (error) {
      console.log("google singin error", error);
    }
    setSubmitting(false);
  };

  const handleDataForLogin = async (signInResult) => {
    const anonymousToken = getAnonymousToken();
    if (anonymousToken) {
      const planSnap = await getDoc(doc(db, "plan", anonymousToken));
      const planData = planSnap.data();
      const visited = [
        await detailsValidation.isValid(planData),
        await visionValidation.isValid(planData),
        await valuesValidation.isValid(planData),
        await focusAreaValidation.isValid(planData),
        await objectiveValidation.isValid(planData),
        await projectValidation.isValid(planData),
        await kpiValidation.isValid(planData),
        await validateAll.isValid(planData),
      ];
      const lastVisited = visited.lastIndexOf(true);
      await setDoc(
        doc(db, "userPlans", signInResult.user.uid),
        {
          [anonymousToken]: {
            title: planData.companyName,
            lastChanged: new Date(),
            lastVisited: SPT_PATH_ORDER[lastVisited + 1]?.route || "plan",
            complete: visited.pop(),
          },
        },
        { merge: true }
      );
      enqueueSnackbar("Plan added to dashboard", { variant: "success" });
      return;
    }
  };
  const methods = useForm({
    resolver: yupResolver(validate),
    defaultValues: { email: "", password: "", confirmPassword: "" },
  });

  if (submitting) return <LoadingElement>Signing up user</LoadingElement>;

  return (
    <>
      <Helmet>
        <title>Sign up - strategy planning tool</title>
        <meta
          name="description"
          content="Login page for strategy planning tool"
        />
      </Helmet>
      <FormProvider {...methods}>
        <form
          onSubmit={methods.handleSubmit((data) =>
            handleLogin(data.email, data.password)
          )}
        >
          <Container maxWidth="xs">
            <Avatar
              sx={{
                m: "auto",
                bgcolor: "secondary.main",
                mt: 15,
                mb: 1,
              }}
            >
              <AccountCircleIcon />
            </Avatar>
            <Typography
              component="h1"
              variant="h5"
              textAlign="center"
              sx={{ mb: 3 }}
            >
              Sign Up
            </Typography>
            {/* <Box sx={{ mt: 1 }}> */}
            <FormTextInput
              margin="normal"
              id="email"
              placeholder="Email Address"
              name="email"
              fullWidth
            />
            <FormTextInput
              margin="normal"
              fullWidth
              name="password"
              placeholder="Password"
              type="password"
              id="password"
            />
            <FormTextInput
              margin="normal"
              required
              fullWidth
              name="confirmPassword"
              placeholder="Confirm Password"
              type="password"
            />
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
            >
              Sign Up
            </Button>
          </Container>
        </form>
      </FormProvider>
      <Container maxWidth="xs">
        <button
          className="login-with-google-btn"
          // fullWidth
          // variant="text"
          onClick={handleGoogle}
        >
          Sign in with Google
        </button>
        <Grid container justifyContent="flex-end">
          <Grid item>
            <Link
              component={RouterLink}
              to={`/${PATH_STRATEGY_PLANNING}/login`}
              variant="body2"
            >
              {"Already have account? Login"}
            </Link>
          </Grid>
        </Grid>
      </Container>
    </>
  );
};

export default SignUp;
