import FormTextInput from "@Common/components/react-hook-form-ui/FormTextInput";
import HeaderTitle from "@Strategy-planning/components/HeaderTitle";
import { defaultFocusArea } from "@Strategy-planning/data/defaults";
import { SPT_FIELD_NAME } from "@Strategy-planning/tokens/fieldNameToken";
import React from "react";
import { useFieldArray, useFormContext } from "react-hook-form";

const HeaderFocusArea = ({ children }) => {
  const { control } = useFormContext();
  const { fields, remove, insert } = useFieldArray({
    control,
    name: SPT_FIELD_NAME.FOCUS_AREA,
  });

  const hasOneField = fields.length === 1;
  return (
    <>
      {fields.map((value, index) => {
        const handleFunctions = {
          handleAddAbove: () => {
            insert(index, defaultFocusArea);
          },

          handleAddBelow: () => {
            insert(index + 1, defaultFocusArea);
          },
          handleDelete: !hasOneField
            ? () => {
                remove(index);
              }
            : null,
        };
        return (
          <div key={value.id}>
            <HeaderTitle variant="focusArea" {...handleFunctions}>
              Focus area
            </HeaderTitle>
            <FormTextInput
              inputProps={{ sx: { fontSize: "20px", fontWeight: 700 } }}
              name={`${SPT_FIELD_NAME.FOCUS_AREA}[${index}].title`}
              placeholder="Add a focus area"
              variant="standard"
              autosize
            />
            <div style={{ marginBottom: "5px" }} />
            {children(`${SPT_FIELD_NAME.FOCUS_AREA}[${index}]`)}
          </div>
        );
      })}
    </>
  );
};

export default HeaderFocusArea;
