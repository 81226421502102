import React from "react";
import { Controller, useFormContext } from "react-hook-form";
import { get } from "lodash";
import ErrorToolTip from "../ErrorToolTip";
import FreesoloSelect from "../FreesoloSelect";

const FormFreesoloSelect = ({
  name,
  textfieldProps,
  showTooltip = true,
  ...otherProps
}) => {
  const {
    control,
    formState: { errors },
  } = useFormContext();
  const error = get(errors, name)?.message;
  return (
    <>
      <Controller
        name={name}
        render={({ field }) => (
          <ErrorToolTip error={error} showTooltip={showTooltip}>
            <FreesoloSelect
              {...otherProps}
              textfieldProps={{ ...textfieldProps, error: !!error }}
              value={field.value}
              onChange={(e, data) => field.onChange(data)}
              onInputChange={(e, data) => field.onChange(data)}
            />
          </ErrorToolTip>
        )}
        control={control}
      />
      {/* <FreesoloSelect value={first} onChange={(e, data) => setfirst(data)} /> */}
    </>
  );
};

export default FormFreesoloSelect;
