import * as React from "react";

import {
  Box,
  Button,
  Grid,
  IconButton,
  Menu,
  MenuItem,
  Typography,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useSnackbar } from "notistack";
import AccountCircle from "@mui/icons-material/AccountCircle";
import MenuIcon from "@mui/icons-material/Menu";
import Header from "@Common/components/Header";
import FormTextInput from "@Common/components/react-hook-form-ui/FormTextInput";
import { getAnonymousToken } from "@Common/storage/anonymousTokenStorage";
import { userSignOut } from "@Common/firebase";
import { primaryColors } from "@Common/tokens/colors";
import { PATH_STRATEGY_PLANNING } from "@Common/tokens/routeTokens";

const StepperHeader = ({ showCompanyName = true }) => {
  const { enqueueSnackbar } = useSnackbar();
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const navigate = useNavigate();

  return (
    <Header
      leftContent={
        <Grid>
          <Typography
            variant={showCompanyName ? "h6" : "h5"}
            sx={{ fontWeight: 700, mb: "2px" }}
            // color={showCompanyName ? "primary.eggplant" : "primary.purpleRain"}
          >
            Strategy plan
          </Typography>
          {showCompanyName && (
            <FormTextInput
              name="companyName"
              variant="standard"
              autosize
              placeholder="Cascade"
              inputProps={{
                sx: {
                  fontSize: "20px !important",
                  fontWeight: "700 !important",
                  color: primaryColors.purpleRain + " !important",
                },
              }}
            />
          )}
        </Grid>
      }
      rightContent={
        <>
          {getAnonymousToken() ? (
            <>
              <Box sx={{ display: { xs: "none", md: "block" } }}>
                <Button
                  variant="text"
                  color="eggplant"
                  onClick={() => {
                    navigate(`/${PATH_STRATEGY_PLANNING}/login`);
                  }}
                  sx={{ mr: 1 }}
                >
                  Login
                </Button>
                <Button
                  variant="outlined"
                  color="eggplant"
                  onClick={() => {
                    navigate(`/${PATH_STRATEGY_PLANNING}/signUp`);
                  }}
                >
                  Save for later
                </Button>
              </Box>
              <Box sx={{ display: { xs: "block", md: "none" } }}>
                <IconButton size="large" onClick={handleMenu} color="inherit">
                  <MenuIcon />
                </IconButton>
                <Menu
                  id="menu-appbar"
                  anchorEl={anchorEl}
                  anchorOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                  keepMounted
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                  open={Boolean(anchorEl)}
                  onClose={handleClose}
                >
                  <MenuItem
                    onClick={() => {
                      handleClose();
                      navigate(`/${PATH_STRATEGY_PLANNING}/login`);
                    }}
                  >
                    <Typography variant="subtitle1">Login</Typography>
                  </MenuItem>
                  <MenuItem
                    onClick={() => {
                      handleClose();
                      navigate(`/${PATH_STRATEGY_PLANNING}/signUp`);
                    }}
                  >
                    <Typography variant="subtitle1">Signup</Typography>
                  </MenuItem>
                </Menu>
              </Box>
            </>
          ) : (
            <>
              <Box sx={{ display: { xs: "none", md: "block" } }}>
                <Button
                  variant="text"
                  color="eggplant"
                  onClick={() => {
                    userSignOut().then(() => {
                      enqueueSnackbar("Logged out successfully", {
                        variant: "success",
                      });
                      navigate("/");
                    });
                  }}
                  sx={{ mr: 1 }}
                >
                  Logout
                </Button>
                <Button
                  variant="outlined"
                  color="eggplant"
                  onClick={() => {
                    navigate(`/${PATH_STRATEGY_PLANNING}/dashboard`);
                  }}
                >
                  See all plans
                </Button>
              </Box>
              <Box sx={{ display: { xs: "block", md: "none" } }}>
                <IconButton size="large" onClick={handleMenu} color="inherit">
                  <AccountCircle />
                </IconButton>
                <Menu
                  id="menu-appbar"
                  anchorEl={anchorEl}
                  anchorOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                  keepMounted
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                  open={Boolean(anchorEl)}
                  onClose={handleClose}
                >
                  <MenuItem
                    onClick={() => {
                      handleClose();
                      navigate(`/${PATH_STRATEGY_PLANNING}/dashboard`);
                    }}
                  >
                    <Typography variant="subtitle1">See all plans</Typography>
                  </MenuItem>
                  <MenuItem
                    onClick={() => {
                      handleClose();
                      userSignOut().then(() => {
                        enqueueSnackbar("Logged out successfully", {
                          variant: "success",
                        });
                        navigate("/");
                      });
                    }}
                  >
                    <Typography variant="subtitle1">Logout</Typography>
                  </MenuItem>
                </Menu>
              </Box>
            </>
          )}
        </>
      }
    />
  );
};

export default StepperHeader;
