export const actionOptions = [
  "Increase",
  "Reduce",
  "Grow",
  "Deliver",
  "Build",
  "Maintain",
  "CUSTOM (add your own)",
];

export const unitOptions = ["People", "Visits", "CUSTOM (add your own)"];
