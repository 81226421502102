import {
  alpha,
  Box,
  IconButton,
  Menu,
  MenuItem,
  Stack,
  Typography,
} from "@mui/material";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import React from "react";

import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import DeleteIcon from "@mui/icons-material/Delete";
import { primaryColors } from "@Common/tokens/colors";

const PlanBodyMenu = ({
  handleClose,
  anchorEl,
  handleAddBelow,
  handleAddAbove,
  handleDelete,
}) => {
  return (
    <Menu
      id="basic-menu"
      anchorEl={anchorEl}
      open={Boolean(anchorEl)}
      onClose={handleClose}
      MenuListProps={{
        "aria-labelledby": "basic-button",
      }}
    >
      <MenuItem onClick={handleAddAbove}>
        <ArrowUpwardIcon
          sx={{
            fontSize: "14px",
            stroke: primaryColors.eggplant,
            strokeWidth: 0.5,
          }}
        />
        <Typography sx={{ fontSize: "14px", fontWeight: 500, ml: 1 }}>
          Add above
        </Typography>
      </MenuItem>
      <MenuItem onClick={handleAddBelow}>
        <ArrowDownwardIcon
          sx={{
            fontSize: "14px",
            stroke: primaryColors.eggplant,
            strokeWidth: 0.5,
          }}
        />
        <Typography sx={{ fontSize: "14px", fontWeight: 500, ml: 1 }}>
          Add below
        </Typography>
      </MenuItem>
      <MenuItem onClick={handleDelete}>
        <DeleteIcon sx={{ fontSize: "14px" }} />
        {/* <DeleteIcon /> */}
        <Typography sx={{ fontSize: "14px", fontWeight: 500, ml: 1 }}>
          Delete
        </Typography>
      </MenuItem>
    </Menu>
  );
};

const fieldTemplateStyles = {
  p: "0px 5px",
  m: "4px 0px",
  "&:hover": {
    background: alpha("#594BDB", 0.09),
    "& .addIcon": {
      transform: "scaleX(1)",
    },
  },
  borderRadius: 1,
};

const hoverStyle = {
  background: alpha("#594BDB", 0.09),
  "& .addIcon": {
    transform: "scaleX(1)",
  },
};

const RowTemplate = ({
  menu,
  title,
  metric,
  unit,
  date,
  gridStyles,
  defaultVal,
  index,
  insert,
  remove,
  hasEdit = true,
  ...otherProps
}) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleAddAbove = () => {
    insert(index, defaultVal);
    handleClose();
  };

  const handleAddBelow = () => {
    insert(index + 1, defaultVal);
    handleClose();
  };
  const handleDelete = () => {
    remove(index);
    handleClose();
  };
  const handleFunctions = {
    handleAddAbove,
    handleAddBelow,
    handleDelete,
    handleClose,
  };
  const dispatchHover = Boolean(anchorEl) ? hoverStyle : {};
  return (
    <>
      <Stack
        direction="row"
        sx={{ ...fieldTemplateStyles, ...gridStyles, ...dispatchHover }}
        spacing={0}
        // columns={36}
        alignItems="center"
        // onMouseOver={() => alert("hi")}
      >
        <Box sx={{ width: 30 }}>
          {hasEdit && (
            <IconButton
              onClick={handleClick}
              className="addIcon"
              sx={{ transform: "scaleX(0)", borderRadius: 1, p: "2px 4px" }}
            >
              <MoreHorizIcon />
            </IconButton>
          )}
        </Box>
        <Box sx={{ width: 650, pl: "5px" }}>{title}</Box>
        <Box sx={{ width: 100, pl: "5px" }}>{metric}</Box>
        <Box sx={{ width: 120, pl: "5px" }}>{unit}</Box>
        <Box sx={{ width: 240, pl: "5px" }}>{date}</Box>
        <PlanBodyMenu anchorEl={anchorEl} {...handleFunctions} />
      </Stack>
    </>
  );
};

export default RowTemplate;
