import { alpha, Box, Button, Grid, Typography } from "@mui/material";
import React from "react";

import { ReactComponent as TimeIcon } from "@Common/assets/calendar-time-icon.svg";
import { ReactComponent as RightArrowIcon } from "@Common/assets/right-arrow.svg";
import theme from "@Common/themes/theme";
import { primaryColors } from "@Common/tokens/colors";
import ErrorToolTip from "../ErrorToolTip";

const styles = (error, isTitle) => ({
  padding: isTitle ? "0px 11px" : "8.5px 10px",
  width: "100%",
  backgroundColor: !isTitle && "white",
  border:
    !isTitle &&
    `1px solid ${error ? alpha(theme.palette.error.main, 0.8) : "#EAEAEA"}`,
  borderRadius: 2,
  transition: "border-color 100ms ease-in-out",
  "&:hover": !isTitle && {
    borderColor: error ? "#d32f2f" : alpha(primaryColors.purpleRain, 0.5),
    borderWidth: 2,
    padding: "7.5px 9px",
    // boxShadow: `0 0 0 1px ${alpha(primaryColors.purpleRain, 0.5)}`,
  },
  // maxWidth: "300px",
});

const undefinedDateStyles = {
  color: "eggplant.light",
};

const formatDate = (date) => {
  date = new Date(date);
  const year = date.getFullYear().toString().substr(-2);
  const month = date.toLocaleString("default", { month: "short" });
  const day = date.toLocaleString("default", { day: "numeric" });
  return `${day} ${month}, ${year}`;
};

const DateRangeButton = React.forwardRef(
  (
    {
      toError,
      fromError,
      from,
      to,
      handleClick,
      fontStyles,
      hasEndDate = true,
      isTitle = false,
      noIconTitle = false,
      showTooltip = true,
      ...props
    },
    ref
  ) => {
    return (
      <Box
        sx={styles(
          Boolean(toError) || Boolean(fromError),
          isTitle || noIconTitle
        )}
        ref={ref}
      >
        <Grid container justifyContent={"center"} alignItems="center">
          {!noIconTitle && (
            <Grid
              container
              item
              sx={{ width: 40 }}
              justifyContent="center"
              alignItems="center"
            >
              {!isTitle && <TimeIcon />}
            </Grid>
          )}

          <Grid
            container
            item
            sx={{ width: 102, ml: noIconTitle && "-20px" }}
            justifyContent="center"
            alignItems="center"
          >
            <ErrorToolTip error={fromError} showTooltip={showTooltip}>
              <Button
                variant="text"
                sx={{
                  p: "2px 6px",
                  m: 0,
                  bgcolor:
                    Boolean(fromError) && alpha(theme.palette.error.main, 0.2),
                  "&:hover": {
                    backgroundColor: alpha("#594BDB", 0.2),
                  },
                }}
                onClick={handleClick}
                {...props}
              >
                {from ? (
                  <Typography sx={{ ...fontStyles }}>
                    {formatDate(from)}
                  </Typography>
                ) : (
                  <Typography sx={{ ...fontStyles, ...undefinedDateStyles }}>
                    Start
                  </Typography>
                )}
              </Button>
            </ErrorToolTip>
          </Grid>
          <Grid
            container
            item
            sx={{ width: 25 }}
            justifyContent="center"
            alignItems="center"
          >
            <RightArrowIcon />
          </Grid>
          <Grid
            container
            item
            sx={{ width: 102 }}
            justifyContent="center"
            alignItems="center"
          >
            <ErrorToolTip error={toError} showTooltip={showTooltip}>
              {hasEndDate ? (
                <Button
                  variant="text"
                  sx={{
                    p: "2px 6px",
                    m: 0,
                    bgcolor:
                      Boolean(toError) && alpha(theme.palette.error.main, 0.2),
                    "&:hover": {
                      backgroundColor: alpha("#594BDB", 0.2),
                    },
                  }}
                  onClick={handleClick}
                  {...props}
                >
                  {to ? (
                    <Typography sx={{ ...fontStyles }}>
                      {formatDate(to)}
                    </Typography>
                  ) : (
                    <Typography sx={{ ...fontStyles, ...undefinedDateStyles }}>
                      Deadline
                    </Typography>
                  )}
                </Button>
              ) : (
                <Typography sx={{ ...fontStyles, p: "2px 6px" }}>
                  No end
                </Typography>
              )}
            </ErrorToolTip>
          </Grid>
        </Grid>
      </Box>
    );
  }
);

export default DateRangeButton;
