import { primaryColors } from "@Common/tokens/colors";
import { Typography } from "@mui/material";
import React from "react";

const TipsValueContent = () => {
  return (
    <div>
      <Typography variant="h3" sx={{ mb: 2, fontSize: 18 }}>
        What are Values?
      </Typography>
      <Typography variant="subtitle1" sx={{ mb: 5 }}>
        People execute strategies. If your strategy opposes your values, it
        won’t be implemented. There are two sets of values: current and desired.
        If they don’t coincide, you’re in need of a cultural transformation.
      </Typography>
      <Typography variant="h3" sx={{ mb: 2, fontSize: 18 }}>
        How to identify your current values
      </Typography>
      <Typography variant="subtitle1" sx={{ mb: 1 }}>
        This is a discovery process. You <strong>UNCOVER</strong> your current
        values, because your culture already exists.
      </Typography>
      <ul
        style={{
          fontSize: 14,
          fontWeight: 500,
          color: primaryColors.eggplant,
        }}
      >
        <li>What do you tolerate?</li>
        <li>What do you NOT tolerate?</li>
      </ul>
      <Typography variant="subtitle1" sx={{ mb: 2 }}>
        Think of all the activities and behaviors that you actively reward or
        call out.
      </Typography>
      <ul
        style={{
          fontSize: 14,
          fontWeight: 500,
          color: primaryColors.eggplant,
        }}
      >
        <li>What are the internal role models?</li>
        <li>Who do you want people to look up to?</li>
      </ul>
      <Typography variant="subtitle1" sx={{ mb: 2 }}>
        Determine the values their behavior advocates. Your cultural stars model
        your current culture.
      </Typography>
      <Typography variant="subtitle1" sx={{ mb: 5 }}>
        Vision statements typically remain the same, that’s why they are
        unattainable. They rarely outline services and products, but they spark
        human imagination. They paint a clear picture of the ambitious future
        state with simple language, i.e. no jargon.
      </Typography>
    </div>
  );
};

export default TipsValueContent;
