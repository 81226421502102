import { createTheme } from "@mui/material";
import createPalette from "@mui/material/styles/createPalette";
import { primaryColors, secondaryColors } from "../tokens/colors";
import { alpha } from "@mui/material";

const palette = createPalette({
  primary: {
    main: primaryColors.purpleRain,
    contrastText: primaryColors.eggplant,
    ...primaryColors,
  },

  secondary: {
    main: secondaryColors.dustyLavender,
    ...secondaryColors,
  },

  background: {
    default: primaryColors.cloud,
  },

  // divider: primaryColors.eggplant,

  eggplant: {
    main: primaryColors.eggplant,
    light: "grey",
    contrastText: primaryColors.white,
  },
  purpleRain: {
    main: primaryColors.purpleRain,
    light: secondaryColors.dustyLavender,
    contrastText: primaryColors.white,
  },

  grey: {
    main: "#D6D6D6",
    light: "grey",
    contrastText: primaryColors.white,
  },

  action: {
    hover: alpha(primaryColors.purpleRain, 0.08),
  },
});

const theme = createTheme({
  palette,
  typography: {
    fontFamily: "Poppins",
    button: {
      textTransform: "none",
    },
    h1: {
      fontSize: "3.125rem", //50px
      fontWeight: 700,
    },
    h3: {
      fontSize: "1.5rem", //24px
      fontWeight: 700,
    },
    h5: {
      fontSize: "1.25rem", //20px
      fontWeight: 700,
    },
    h6: {
      fontSize: "0.875rem", //14px
      fontWeight: 600,
    },
    subtitle1: {
      fontSize: "0.875rem", //14px
      fontWeight: 500,
    },
    subtitle2: {
      fontSize: "0.75rem", //12px
      fontWeight: 600,
    },
    body1: {
      fontSize: "1rem", //16px
      fontWeight: 500,
    },
  },
  zIndex: {
    tooltip: 1250,
  },
});

theme.components = {
  MuiAppBar: {
    styleOverrides: {
      root: {
        backgroundColor: palette.primary.white,
      },
    },
  },
  MuiTypography: {
    styleOverrides: {
      root: {
        color: palette.eggplant.main,
      },
    },
  },
  MuiTextField: {
    defaultProps: {
      hiddenLabel: true,
      variant: "outlined",
    },
  },
  MuiOutlinedInput: {
    styleOverrides: {
      root: {
        borderRadius: 8,
        backgroundColor: "white",
      },
      input: {},
    },
  },
  MuiAutocomplete: {
    styleOverrides: {
      root: {
        // padding: "0px",
      },
    },
  },
  MuiButton: {
    defaultProps: {
      disableRipple: true,
      variant: "contained",
      color: "purpleRain",
    },
    styleOverrides: {
      root: {
        padding: "6px 35px",
        fontWeight: 500,
      },
    },
  },
  MuiIconButton: {
    defaultProps: {
      disableRipple: true,
    },
  },
  MuiFormHelperText: {
    styleOverrides: {
      root: {
        // Use existing space / prevents shifting content below field
        marginTop: 0,
        height: 0,
      },
    },
  },
  MuiMenu: {
    styleOverrides: {
      list: {
        border: "1px solid #EAEAEA",
        borderRadius: "4px",
      },
    },
  },
};

export default theme;
