import FormDateRangeSelect from "@Common/components/react-hook-form-ui/FormDateRangeSelect";
import { primaryColors } from "@Common/tokens/colors";
import { alpha } from "@mui/material";
import { defaultProject } from "@Strategy-planning/data/defaults";
import { SPT_FIELD_NAME } from "@Strategy-planning/tokens/fieldNameToken";
import React from "react";
import { useFieldArray, useFormContext } from "react-hook-form";
import ProjectKPITitle from "./components/ProjectKPITitle";
import RowTemplate from "./components/RowTemplate";

const ProjectBody = ({ name, showDate }) => {
  const fieldName = `${name}.${SPT_FIELD_NAME.PROJECT}`;
  const { control } = useFormContext();
  const { fields, ...arrayHelpers } = useFieldArray({
    control,
    name: fieldName,
  });

  return (
    <div>
      {fields.map((value, index) => {
        const isEven = !(index % 2 === 0);
        return (
          <div key={value.id}>
            <RowTemplate
              aria-label="ProjectGrid"
              {...arrayHelpers}
              defaultVal={defaultProject}
              index={index}
              title={
                <ProjectKPITitle
                  type="project"
                  label="Project"
                  name={`${fieldName}[${index}]`}
                />
              }
              date={
                showDate && (
                  <FormDateRangeSelect
                    noIconTitle
                    fontStyles={{
                      fontSize: "14px",
                      fontWeight: 500,
                      color:
                        alpha(primaryColors.eggplant, 0.87) + " !important",
                    }}
                    name={`${fieldName}[${index}]`}
                    showTooltip={false}
                  />
                )
              }
              gridStyles={{
                margin: "2px 0px",
                backgroundColor: isEven && alpha("#000", 0.03),
              }}
            />
          </div>
        );
      })}
    </div>
  );
};

export default ProjectBody;
