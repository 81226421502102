import { alpha, styled, TextField } from "@mui/material";
import React from "react";
import AutosizeTextInput from "./AutosizeTextInput";

export const CascadeTextField = styled(TextField)(
  ({ theme, color = "primary", error = false, variant = "outlined" }) => ({
    input: {
      // if below removed nice purple shade is placeholder
      padding: variant !== "standard" ? "12px 14px" : "0px 0px 2px 0px",
      "&::placeholder": {
        // color: "#9f9f9f",
        color: "#CACACA",
        opacity: "unset",
      },
    },
    // For standard variant style below
    "& .MuiInput-underline:before": {
      borderBottom: "2px solid transparent",
    },
    "&& .MuiInput-underline:hover:before": {
      borderBottom: "2px solid #8a81e5",
    },
    "& .MuiInput-underline:after": {
      transition: theme.transitions.create(["transform", "opacity"]),
    },
    "& .MuiInputBase-input": {
      "& .MuiInput-input": {
        fontSize: variant !== "standard" ? "1rem" : "0.875rem", //16px | 14px
        fontWeight: 500,
        color: theme.palette.primary.eggplant,
      },
    },
    "& .MuiInput-root": {
      "&.Mui-focused: after": {
        opacity: 1,
        transform: "scaleX(1)",
      },
    },
    "& .MuiInput-root:after": {
      opacity: 0,
      transform: "scaleX(1)",
    },
    "& .Mui-error:after": {
      opacity: 1,
    },

    // Outlined variant styles below
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        transition: "border-color 100ms ease-in-out",
        // transition: theme.transitions.create(["border"]),
        borderColor: !error ? "#EAEAEA" : alpha(theme.palette.error.main, 0.8),
      },
      "&:hover fieldset": {
        borderColor: !error
          ? alpha(theme.palette[color].main, 0.5)
          : theme.palette.error.main,
        borderWidth: 2,
      },
      "&.Mui-focused fieldset": {
        borderColor: !error
          ? theme.palette[color].main
          : theme.palette.error.main,
      },
    },
  })
);

const TextInput = React.forwardRef(({ autosize = false, ...props }, ref) => {
  if (autosize) return <AutosizeTextInput {...props} ref={ref} />;
  return (
    <>
      <CascadeTextField {...props} ref={ref} />
    </>
  );
});

export default TextInput;
