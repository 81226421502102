export const primaryColors = {
  purpleRain: "#594BDB",
  eggplant: "#1D1652",
  cloud: "#F8F8FB",
  white: "#FFFFFF",
  grey: "#EAEAEA",
};

export const secondaryColors = {
  darkEggplant: "#0C0924",
  nightshade: "#080618",
  dustyLavender: "#6E698F",
  sunshine: "#F5CF45",
};
