import * as React from "react";
import { AppBar, Stack, Box } from "@mui/material";
import { useLocation } from "react-router-dom";
import { SPT_PATH_ORDER } from "@Strategy-planning/tokens/strategyPlanningPaths";
import FooterTooltip from "./FooterTooltip";
import { useFormContext } from "react-hook-form";
import SubmitButton from "./SubmitButton";

const StepperFooter = () => {
  const { pathname } = useLocation();
  const { getValues } = useFormContext();
  const visited = getValues("visited");

  return (
    <>
      <Box sx={{ mt: "200px" }}>
        <AppBar
          elevation={2}
          position="fixed"
          sx={{ top: "auto", bottom: 0, pb: 2, pt: 3 }}
        >
          <Stack direction="row" justifyContent="center" flexWrap="wrap">
            {SPT_PATH_ORDER.map((route, index) => {
              const isCurrentTab =
                pathname.split("/").pop() === route.fullPath.split("/").pop();
              return (
                <FooterTooltip
                  key={route.title}
                  visibilityCheck={isCurrentTab}
                  title={route.title}
                >
                  <SubmitButton
                    clickable={!(!isCurrentTab && !visited[index])}
                    nextPage={route.fullPath}
                    color={
                      visited[index] || isCurrentTab ? "purpleRain" : "grey"
                    }
                    sx={{
                      opacity: isCurrentTab && 0.6,
                      p: "4px 35px",
                      mt: 1,
                      mr: "4px",
                      ml: "4px",
                    }}
                    label=""
                  />
                </FooterTooltip>
              );
            })}
          </Stack>
        </AppBar>
      </Box>
    </>
  );
};

export default StepperFooter;
