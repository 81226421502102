import { Box } from "@mui/material";
import React, { useState, useRef, useEffect } from "react";
import { CascadeTextField } from "./TextInput";

const AutosizeTextInput = React.forwardRef((props, ref) => {
  const [content, setContent] = useState("");
  const [width, setWidth] = useState(0);
  const inputWidthCalculatorRef = useRef();
  const placeholderRef = useRef();

  useEffect(() => {
    if (content || props.value) {
      setWidth(inputWidthCalculatorRef.current.offsetWidth + 1);
    } else {
      setWidth(placeholderRef.current.offsetWidth);
    }
  }, [content, props?.value]);

  const changeHandler = (evt) => {
    setContent(evt.target.value);
  };
  return (
    <>
      <Box
        style={{
          position: "absolute",
          opacity: 0,
          zIndex: -100,
          whiteSpace: "pre",
          overflow: "scroll",
          maxWidth: "100%",
        }}
        sx={{ ...props?.inputProps?.sx, ...props?.InputProps?.sx }}
        ref={inputWidthCalculatorRef}
      >
        {props.value ? props.value : content}
      </Box>
      <Box
        style={{
          position: "absolute",
          opacity: 0,
          zIndex: -100,
          whiteSpace: "pre",
          overflow: "scroll",
          maxWidth: "100%",
        }}
        sx={{ ...props?.inputProps?.sx, ...props?.InputProps?.sx }}
        ref={placeholderRef}
      >
        {props?.placeholder}
      </Box>
      <CascadeTextField
        ref={ref}
        value={content}
        onChange={changeHandler}
        {...props}
        inputProps={{
          ...props.inputProps,
          style: { width: props?.type === "number" ? width + 20 : width },
        }}
        style={{ maxWidth: "100%" }}
      />
    </>
  );
});

export default AutosizeTextInput;
