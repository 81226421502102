import { primaryColors } from "@Common/tokens/colors";
import { Typography } from "@mui/material";
import React from "react";

const TipsObjectiveContent = () => {
  return (
    <div>
      <Typography variant="h3" sx={{ mb: 2, fontSize: 18 }}>
        What is an Objective?
      </Typography>
      <Typography
        variant="subtitle1"
        color={primaryColors.eggplant}
        sx={{ mb: 5 }}
      >
        Strategic objectives are tangible, specific and once completed, new ones
        take their place. They populate your Focus Areas. Each objective may
        contribute to more than one Focus Area.
      </Typography>
      <Typography variant="h3" sx={{ mb: 2, fontSize: 18 }}>
        Keep in mind that
      </Typography>
      <ul
        style={{
          fontSize: 14,
          fontWeight: 500,
          color: primaryColors.eggplant,
        }}
      >
        <li>
          <Typography sx={{ fontWeight: 700 }}>Jargon is allowed</Typography>
          Impossible and inspiring like a big dream.
        </li>
        <li>
          <Typography sx={{ fontWeight: 700 }}>It’s a team exercise</Typography>
          Involve your team in the brainstorming sessions to leverage their
          expertise and knowledge of the front line.
        </li>
      </ul>
      <Typography variant="h3" sx={{ mt: 5, mb: 2, fontSize: 18 }}>
        How to write a strategic objective
      </Typography>
      <Typography variant="subtitle1" sx={{ mb: 5 }}>
        <span style={{ color: "#3232FF" }}>Action (Verb)</span> +
        <span style={{ color: "#FF9902" }}> Detail</span> +
        <span style={{ color: "#9900FF" }}> Deadline</span>
        <br />
        Ex. <span style={{ color: "#3232FF" }}>Create </span>
        <span style={{ color: "#FF9902" }}>
          the standard for quality bikes{" "}
        </span>
        <span style={{ color: "#9900FF" }}>by 31st of December 2022</span>
      </Typography>
      <Typography variant="h3" sx={{ mt: 5, mb: 2, fontSize: 18 }}>
        Once you’re done, go back
      </Typography>
      <Typography variant="subtitle1" sx={{ mb: 2 }}>
        Iteration is your friend. You won’t get things right the first time.
        That’s OK. Ask yourself the following questions:{" "}
      </Typography>
      <ul
        style={{
          fontSize: 14,
          fontWeight: 500,
          color: primaryColors.eggplant,
        }}
      >
        <li>
          Does every Focus Area have enough objectives to be accomplished?
        </li>
        <li>
          Does every team member understand and agree with the objectives?
        </li>
        <li>Do we have the resources to pursue every objective effectively?</li>
      </ul>
    </div>
  );
};

export default TipsObjectiveContent;
