import FormTextInput from "@Common/components/react-hook-form-ui/FormTextInput";
import { defaultFocusArea } from "@Strategy-planning/data/defaults";
import { SPT_FIELD_NAME } from "@Strategy-planning/tokens/fieldNameToken";
import React from "react";
import { useFieldArray, useFormContext } from "react-hook-form";
import RowTemplate from "./components/RowTemplate";
import ObjectiveBody from "./ObjectiveBody";

const FocusAreaBody = ({ showDate }) => {
  const { control } = useFormContext();
  const { fields, ...focusHelpers } = useFieldArray({
    control,
    name: SPT_FIELD_NAME.FOCUS_AREA,
  });

  return (
    <div>
      {fields.map((value, index) => {
        return (
          <div key={value.id}>
            <RowTemplate
              {...focusHelpers}
              defaultVal={defaultFocusArea}
              index={index}
              title={
                <FormTextInput
                  name={`${SPT_FIELD_NAME.FOCUS_AREA}[${index}].title`}
                  placeholder="Enter value"
                  variant="standard"
                  autosize
                  autoFocus
                  showTooltip={false}
                />
              }
            />
            <ObjectiveBody
              name={`${SPT_FIELD_NAME.FOCUS_AREA}[${index}]`}
              showDate={showDate}
            />
          </div>
        );
      })}
    </div>
  );
};

export default FocusAreaBody;
