import * as React from "react";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import { Grid, IconButton, Menu, MenuItem } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useSnackbar } from "notistack";
import AccountCircle from "@mui/icons-material/AccountCircle";
import { httpsCallable } from "firebase/functions";
import { saveAs } from "file-saver";
import { Box } from "@mui/system";
import MenuIcon from "@mui/icons-material/Menu";
import { functions, userSignOut } from "@Common/firebase";
import Header from "@Common/components/Header";
import FormTextInput from "@Common/components/react-hook-form-ui/FormTextInput";
import { primaryColors } from "@Common/tokens/colors";
import { getAnonymousToken } from "@Common/storage/anonymousTokenStorage";
import { PATH_STRATEGY_PLANNING } from "@Common/tokens/routeTokens";
import SubmitButton from "@Strategy-planning/layouts/StepperLayout/components/SubmitButton";

const PlanHeader = ({ showCompanyName = true }) => {
  const { enqueueSnackbar } = useSnackbar();
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const navigate = useNavigate();

  const handleDownload = async (data) => {
    const addMessage = httpsCallable(functions, "dataToExcel");
    const buffer = await addMessage(data);
    saveAs(
      new Blob([Uint8Array.from(atob(buffer.data), (c) => c.charCodeAt(0))], {
        type: "application/octet-stream",
      }),
      "Plan.xlsx"
    );
  };

  return (
    <>
      <Header
        leftContent={
          <Grid>
            <Typography
              variant={showCompanyName ? "h6" : "h5"}
              sx={{ fontWeight: 700, mb: "2px" }}
              // color={showCompanyName ? "primary.eggplant" : "primary.purpleRain"}
            >
              Strategy plan
            </Typography>
            {showCompanyName && (
              <FormTextInput
                name="companyName"
                variant="standard"
                autosize
                placeholder="Cascade"
                inputProps={{
                  sx: {
                    fontSize: "20px !important",
                    fontWeight: "700 !important",
                    color: primaryColors.purpleRain + " !important",
                  },
                }}
              />
            )}
          </Grid>
        }
        rightContent={
          <>
            <Box
              sx={{
                display: { xs: "none", sm: "block" },
              }}
            >
              <SubmitButton
                nextFunction={handleDownload}
                variant="outlined"
                color="eggplant"
                sx={{ mr: 1 }}
                label="Download as Excel"
              />
            </Box>
            {getAnonymousToken() ? (
              <>
                <Box sx={{ display: { xs: "none", md: "block" } }}>
                  <Button
                    variant="outlined"
                    color="eggplant"
                    onClick={() => {
                      navigate(`/${PATH_STRATEGY_PLANNING}/login`);
                    }}
                  >
                    Login/Save for later
                  </Button>
                </Box>
                <Box sx={{ display: { xs: "block", md: "none" } }}>
                  <IconButton
                    size="large"
                    aria-label="account of current user"
                    aria-controls="menu-appbar"
                    aria-haspopup="true"
                    onClick={handleMenu}
                    color="inherit"
                  >
                    <MenuIcon />
                  </IconButton>
                  <Menu
                    id="menu-appbar"
                    anchorEl={anchorEl}
                    anchorOrigin={{
                      vertical: "top",
                      horizontal: "right",
                    }}
                    keepMounted
                    transformOrigin={{
                      vertical: "top",
                      horizontal: "right",
                    }}
                    open={Boolean(anchorEl)}
                    onClose={handleClose}
                  >
                    <Box
                      sx={{
                        display: { xs: "block", sm: "none" },
                      }}
                    >
                      <MenuItem onClick={handleDownload}>
                        <Typography variant="subtitle1" sx={{ m: 0 }}>
                          Download as Excel
                        </Typography>
                      </MenuItem>
                    </Box>
                    <MenuItem
                      onClick={() => {
                        handleClose();
                        navigate(`/${PATH_STRATEGY_PLANNING}/signUp`);
                      }}
                    >
                      <Typography variant="subtitle1">
                        Save for later
                      </Typography>
                    </MenuItem>
                    <MenuItem
                      onClick={() => {
                        handleClose();
                        navigate(`/${PATH_STRATEGY_PLANNING}/login`);
                      }}
                    >
                      <Typography variant="subtitle1">Login</Typography>
                    </MenuItem>
                  </Menu>
                </Box>
              </>
            ) : (
              <>
                <IconButton
                  size="large"
                  aria-label="account of current user"
                  aria-controls="menu-appbar"
                  aria-haspopup="true"
                  onClick={handleMenu}
                  color="inherit"
                >
                  <AccountCircle />
                </IconButton>
                <Menu
                  id="menu-appbar"
                  anchorEl={anchorEl}
                  anchorOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                  keepMounted
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                  open={Boolean(anchorEl)}
                  onClose={handleClose}
                >
                  <Box
                    sx={{
                      display: { xs: "block", sm: "none" },
                    }}
                  >
                    <MenuItem>
                      <SubmitButton
                        nextFunction={handleDownload}
                        variant="text"
                        color="eggplant"
                        sx={{ p: 0, m: 0 }}
                        label="Download as Excel"
                      />
                      {/* <Typography variant="subtitle1" sx={{ m: 0 }}>
                        Download as Excel
                      </Typography> */}
                    </MenuItem>
                  </Box>
                  <MenuItem
                    onClick={() => {
                      handleClose();
                      navigate(`/${PATH_STRATEGY_PLANNING}/dashboard`);
                    }}
                  >
                    <Typography variant="subtitle1">See all plans</Typography>
                  </MenuItem>
                  <MenuItem
                    onClick={() => {
                      handleClose();
                      userSignOut().then(() => {
                        enqueueSnackbar("Logged out successfully", {
                          variant: "success",
                        });
                        navigate("/");
                      });
                    }}
                  >
                    <Typography variant="subtitle1">Logout</Typography>
                  </MenuItem>
                </Menu>
              </>
            )}
          </>
        }
      />
    </>
  );
};

export default PlanHeader;
