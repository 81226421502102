import { Button, Grid, Typography } from "@mui/material";
import React from "react";
import ControlPointIcon from "@mui/icons-material/ControlPoint";

const AddButton = React.memo(({ children, ...otherProps }) => {
  return (
    <div style={{ justifyContent: "center" }}>
      <Button
        variant="text"
        color="eggplant"
        fullWidth
        sx={{ mt: 3, mb: 2 }}
        {...otherProps}
      >
        <Grid container spacing={1} justifyContent="center" alignItems="center">
          <Grid item sx={{ height: "32px" }}>
            <ControlPointIcon />
          </Grid>
          <Grid item>
            <Typography
              variant="h6"
              color="primary.eggplant"
              sx={{ fontSize: "14px" }}
            >
              {children}
            </Typography>
          </Grid>
        </Grid>
      </Button>
    </div>
  );
});

export default AddButton;
