import React from "react";
import { Tooltip as MuiTooltip, tooltipClasses } from "@mui/material";
import { styled } from "@mui/system";
import Fade from "@mui/material/Fade";

const CustomTooltip = styled(({ className, ...props }) => (
  <MuiTooltip {...props} arrow classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    "&:before": {
      border: "1px solid lightgrey",
    },
    color: theme.palette.common.white,
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.white,
    border: "1px solid lightgrey",
    color: theme.palette.primary.eggplant,
    fontWeight: 600,
    fontSize: "12px",
  },
}));

const FooterTooltip = ({ visibilityCheck, title, children }) => {
  const [open, setOpen] = React.useState(visibilityCheck);
  const handleClose = (check) => {
    if (!check) {
      setOpen(false);
    }
  };

  const handleOpen = () => {
    setOpen(true);
  };
  return (
    <CustomTooltip
      TransitionComponent={Fade}
      TransitionProps={{ timeout: 0 }}
      open={open}
      placement="top"
      onClose={() => handleClose(visibilityCheck)}
      onOpen={handleOpen}
      title={title}
      arrow
    >
      {children}
    </CustomTooltip>
  );
};

export default FooterTooltip;
