import React from "react";
import DayPicker from "react-day-picker";
import NavbarForm from "./NavbarForm";
import YearMonthForm from "./YearMonthForm";

const DatePicker = ({ selectedDay, setSelectedDay, ...props }) => {
  const handleDayClick = (day, { selected }) => {
    setSelectedDay(selected ? null : day);
  };
  const [month, setMonth] = React.useState(selectedDay);
  return (
    <div>
      <DayPicker
        // className="Range"
        month={month}
        selectedDays={selectedDay}
        onDayClick={handleDayClick}
        captionElement={({ date, localeUtils }) => (
          <YearMonthForm
            date={date}
            localeUtils={localeUtils}
            onChange={setMonth}
          />
        )}
        navbarElement={<NavbarForm />}
        {...props}
      />
    </div>
  );
};

export default DatePicker;
