import FormTextInput from "@Common/components/react-hook-form-ui/FormTextInput";
import { Grid, IconButton } from "@mui/material";
import { valuesValidation } from "@Strategy-planning/data/validations";
import FormContainer from "@Strategy-planning/layouts/FormContainer";
import StepperLayout from "@Strategy-planning/layouts/StepperLayout/StepperLayout";
import { SPT_FIELD_NAME } from "@Strategy-planning/tokens/fieldNameToken";
import React from "react";
import { useFieldArray, useFormContext } from "react-hook-form";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import AddButton from "@Common/components/AddButton";
import { defaultValues } from "@Strategy-planning/data/defaults";
import { Helmet } from "react-helmet-async";
import TipsValueContent from "@Strategy-planning/pages/Plan/tipsLayout/TipsValuesContent";

const formData = {
  validationSchema: valuesValidation,
};

const detailsData = {
  title: "What are your values?",
  details:
    "Values answer a simple question: how do we do what we do? Leaders and employees should be proud of them, as they define the organization's culture and identity. Values are the core principles for all associates, and customers should experience them through each service and product.",
  endDivider: false,
  tipsTitle: "Values",
  tipsContent: <TipsValueContent />,
};

export const ValuesFields = () => {
  const { control } = useFormContext();
  const { fields, append, remove } = useFieldArray({
    control,
    name: SPT_FIELD_NAME.VALUE,
  });
  const hasOneField = fields.length === 1;
  return (
    <>
      {fields.map((value, index) => (
        <Grid
          container
          alignItems="center"
          spacing={1}
          sx={{ mt: 1, mb: 1 }}
          key={value.id}
        >
          <Grid item flexGrow={1}>
            <FormTextInput
              name={`${SPT_FIELD_NAME.VALUE}[${index}].title`}
              placeholder="Add a business value"
              fullWidth
              autoFocus
            />
          </Grid>
          <Grid item>
            {!hasOneField && (
              <IconButton onClick={() => remove(index)}>
                <CloseRoundedIcon />
              </IconButton>
            )}
          </Grid>
        </Grid>
      ))}
      <AddButton onClick={() => append(defaultValues)}>Add Values</AddButton>
    </>
  );
};

const Values = ({ initialValues }) => {
  return (
    <div>
      <Helmet>
        <meta
          name="description"
          content="Values page for strategy planning tool"
        />
      </Helmet>
      <FormContainer
        initialValues={initialValues}
        validationSchema={formData.validationSchema}
      >
        <StepperLayout detailsData={detailsData} maxWidth="md">
          <ValuesFields />
        </StepperLayout>
      </FormContainer>
    </div>
  );
};

export default Values;
