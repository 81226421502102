import { Tooltip } from "@mui/material";
import React from "react";

const ErrorToolTip = ({ error, children, showTooltip }) => {
  const [open, setOpen] = React.useState(Boolean(false));
  const [data, setData] = React.useState("");
  React.useEffect(() => {
    if (showTooltip) {
      setOpen(Boolean(error));
      if (error) {
        setData(error);
      }
    }
  }, [error, showTooltip]);

  return (
    <Tooltip
      placement="top"
      //   TransitionComponent={Zoom}
      //   TransitionProps={{ timeout: 20 }}
      open={open}
      title={data}
      arrow
    >
      {children}
    </Tooltip>
  );
};

export default ErrorToolTip;
