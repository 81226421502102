import * as React from "react";
import { Paper, Popper, Typography, Autocomplete } from "@mui/material";
import TextInput from "./TextInput";
import { actionOptions } from "@Strategy-planning/tokens/dropDownOptions";
/**
 * TODO: Add user values to cookies so that they can use it on the next select
 * ? add a form helper text like cselect <FormHelperText>Select verb</FormHelperText>
 */

const TitlePopper = (props) => {
  return (
    <Popper
      {...props}
      style={{ width: "fit-content" }}
      placement="bottom-start"
    />
  );
};

const FreesoloSelect = React.forwardRef(
  (
    { value, setValue, textfieldProps, options, optionStyles = {}, ...props },
    ref
  ) => {
    const isStandard = textfieldProps?.variant === "standard";
    const isAutosize = textfieldProps?.autosize === true;
    const isTitle = isStandard && isAutosize;
    const renderOption = (props, option) => (
      <li
        {...props}
        style={{
          paddingBottom: isTitle && "2px",
          paddingTop: isTitle && "2px",
        }}
      >
        <Typography
          variant={isTitle ? "subtitle1" : "body1"}
          sx={{ p: 0, ...optionStyles }}
        >
          {option.title ? option.title : option}
        </Typography>
      </li>
    );

    const PaperComponent = ({ children }) => (
      <Paper elevation={0}>{children}</Paper>
    );

    const defaultRenderInput = (params) => (
      <TextInput
        {...textfieldProps}
        {...params}
        InputProps={{
          ...params?.InputProps,
          ...textfieldProps?.InputProps,
          sx: {
            paddingTop: "5px !important",
            paddingBottom: "5px !important",
            ...textfieldProps?.InputProps?.sx,
          },
        }}
      />
    );

    const titleRenderInput = (params) => (
      <TextInput
        value={params.inputProps.value}
        {...textfieldProps}
        {...params}
        fullWidth={false}
        InputProps={{
          ...params?.InputProps,
          ...textfieldProps?.InputProps,
        }}
      />
    );

    const defaultautoCompleteProps = {
      value,
      selectOnFocus: true,
      disableClearable: true,
      // handleHomeEndKeys: true,
      options: options ? options : actionOptions,
      PaperComponent,
      renderOption,
      freeSolo: true,
      forcePopupIcon: true,
      renderInput: defaultRenderInput,
      ...props,
    };

    const titleAutoCompleteProps = {
      ...defaultautoCompleteProps,
      renderInput: titleRenderInput,
      PopperComponent: TitlePopper,
      forcePopupIcon: false,
      sx: {
        "&.MuiAutocomplete-root": {
          ".MuiAutocomplete-inputRoot": {
            padding: "0px",
          },
          ".MuiInput-root": {
            ".MuiInput-input": {
              padding: "0px 0px 2px 0px",
            },
          },
        },
        ...props.sx,
      },
    };

    const autoCompleteProps = isTitle
      ? titleAutoCompleteProps
      : defaultautoCompleteProps;

    return (
      <>
        <Autocomplete ref={ref} {...autoCompleteProps} />
      </>
    );
  }
);

export default FreesoloSelect;
