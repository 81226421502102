import Header from "@Common/components/Header";
import { Container, Stack, Typography } from "@mui/material";
import React from "react";
import ButtonInputSelector from "./ButtonInputSelector";
import { Accordion, AccordionDetails, AccordionSummary } from "./Accordion";
import KPIList from "./KPIList";
import { secondaryColors } from "@Common/tokens/colors";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { useLocation } from "react-router-dom";
import { PATH_KPI_LIBRARY_GENERATOR } from "@Common/tokens/routeTokens";

const HeaderTitle = ({ children }) => {
  const { pathname } = useLocation();
  const hasHeader = pathname === `/${PATH_KPI_LIBRARY_GENERATOR}`;
  console.log(hasHeader, pathname);
  return (
    <>
      {hasHeader && <Header hideToolLogoOnXS={false} />}
      <Container maxWidth="sm" sx={{ mt: hasHeader ? "-25px" : "30px" }}>
        <Typography
          variant="subtitle1"
          align={hasHeader ? "left" : "center"}
          sx={{
            fontWeight: 700,
            color: secondaryColors.dustyLavender,
          }}
        >
          FREE TOOL
        </Typography>
        <Typography
          variant="h3"
          align={hasHeader ? "left" : "center"}
          sx={{ mb: 4 }}
        >
          Generate KPIs for your team plan
        </Typography>
        {children}
        <div style={{ height: hasHeader ? "150px" : "30px" }} />
      </Container>
    </>
  );
};

const KPILibraryGenerator = () => {
  const [functionInput, setFunctionInput] = React.useState(null);
  const [functionOpen, setFunctionOpen] = React.useState(true);
  React.useEffect(() => {
    if (functionInput) {
      setFunctionOpen(false);
      window.gtag("event", "user_engagement_kpi_generator", {
        event_category: "function",
        event_label: functionInput,
      });
    }
  }, [functionInput]);

  const [industry, setIndustry] = React.useState(null);
  const [industryOpen, setIndustryOpen] = React.useState(true);

  const handleSetIndustry = (item) => {
    window.gtag("event", "user_engagement_kpi_generator", {
      event_category: "industry",
      event_label: item,
    });
    setIndustry(item);
    setIndustryOpen(false);
    setFunctionInput(null);
    setFunctionOpen(true);
  };
  const handleFunctionToggle = () => {
    setFunctionOpen(!functionOpen);
  };
  const handleIndustryToggle = () => {
    setIndustryOpen(!industryOpen);
  };
  return (
    <HeaderTitle>
      <Accordion
        expanded={industryOpen}
        onChange={industry && handleIndustryToggle}
      >
        <AccordionSummary
          sx={{
            cursor: !industry && "default",
            width: "300px",
            ":hover:not(.Mui-disabled)": {
              cursor: !industry && "default",
            },
          }}
          expandIcon={industry && <ArrowDropDownIcon />}
        >
          <Stack direction="column">
            {industry && (
              <Typography
                variant="subtitle2"
                sx={{ fontSize: "11px", color: secondaryColors.dustyLavender }}
              >
                Industry
              </Typography>
            )}
            <Typography
              variant="body1"
              sx={{ fontWeight: 700 }}
              color="primary.purpleRain"
            >
              {industry ? industry : "Select an industry"}
            </Typography>
          </Stack>
        </AccordionSummary>
        <AccordionDetails>
          <ButtonInputSelector
            value={industry}
            setValue={handleSetIndustry}
            type="industry"
          />
        </AccordionDetails>
      </Accordion>
      {industry && (
        <Accordion
          sx={{
            mt: 1,
          }}
          expanded={functionOpen}
          onChange={functionInput && handleFunctionToggle}
        >
          <AccordionSummary
            sx={{
              cursor: !functionInput && "default",
              width: "300px",
              ":hover:not(.Mui-disabled)": {
                cursor: !functionInput && "default",
              },
            }}
            expandIcon={functionInput && <ArrowDropDownIcon />}
          >
            <Stack direction="column">
              {functionInput && (
                <Typography
                  variant="subtitle2"
                  sx={{
                    fontSize: "11px",
                    color: secondaryColors.dustyLavender,
                  }}
                >
                  Function
                </Typography>
              )}

              <Typography
                variant="body1"
                sx={{ fontWeight: 700 }}
                color="primary.purpleRain"
              >
                {functionInput ? functionInput : "Select a function"}
              </Typography>
            </Stack>
          </AccordionSummary>
          <AccordionDetails>
            <ButtonInputSelector
              value={functionInput}
              setValue={setFunctionInput}
              type="function"
              industryValue={industry}
            />
          </AccordionDetails>
        </Accordion>
      )}
      {/* <Divider sx={{ mt: 3, mb: 3, borderBottomWidth: 5 }}>
        <Chip
          label="Great! Here's your list of KPIs"
          sx={{ background: "#DCF9DA" }}
        />
      </Divider> */}
      {functionInput && industry && (
        <KPIList functionInput={functionInput} industry={industry} />
      )}
    </HeaderTitle>
  );
};

export default KPILibraryGenerator;
