import React from "react";
import { FormProvider, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import LoadingElement from "@Common/components/LoadingElement";

const FormContainer = ({ initialValues, validationSchema, children }) => {
  const methods = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues: initialValues,
  });
  const {
    handleSubmit,
    formState: { isSubmitting },
  } = methods;

  const submitHandler = (data) => {
    console.log(data);
  };
  console.log(initialValues);
  if (isSubmitting) return <LoadingElement>Saving plan</LoadingElement>;
  return (
    <div>
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(submitHandler)}>{children}</form>
      </FormProvider>
    </div>
  );
};

export default FormContainer;
