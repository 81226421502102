import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Stack,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import React from "react";
import InfoRoundedIcon from "@mui/icons-material/InfoRounded";

const DeleteConfirmation = ({
  open,
  content,
  title,
  handleClose,
  handleDelete,
}) => {
  return (
    <div>
      <Dialog open={open} maxWidth="sm" fullWidth>
        <Box sx={{ p: 2 }}>
          <DialogTitle>
            <Stack direction="row" alignItems="center" spacing={1}>
              <InfoRoundedIcon />
              <Typography variant="h5" sx={{ fontSize: "17px" }}>
                {title}
              </Typography>
            </Stack>
          </DialogTitle>
          <Divider sx={{ width: "90%", m: "auto" }} />
          <DialogContent>
            <Typography>{content}</Typography>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} sx={{ bgcolor: "#1A144A" }}>
              Cancel
            </Button>
            <Button
              onClick={() => {
                handleDelete();
                handleClose();
              }}
              sx={{ bgcolor: "#CB4319" }}
            >
              Confirm
            </Button>
          </DialogActions>
        </Box>
      </Dialog>
    </div>
  );
};

export default DeleteConfirmation;
