import {
  Typography,
  Grid,
  IconButton,
  Divider,
  alpha,
  Dialog,
  DialogTitle,
  DialogContent,
  useMediaQuery,
} from "@mui/material";
import React from "react";

import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import { primaryColors } from "@Common/tokens/colors";
import InfoButton from "@Common/components/InfoButton";
import theme from "@Common/themes/theme";
import { useLocation, useParams } from "react-router-dom";
import { getAnonymousToken } from "@Common/storage/anonymousTokenStorage";
import { auth } from "@Common/firebase";

const userDetails = () => {
  if (getAnonymousToken()) return "Unknown";
  return auth.currentUser.uid;
};

const PlanDetails = ({
  title,
  details,
  endDivider = true,
  showTips = true,
  tipsContent,
  tipsTitle,
}) => {
  const { pathname } = useLocation();
  const { planId } = useParams();
  const [modalOpen, setModalOpen] = React.useState(false);
  const handleOpen = () => setModalOpen(true);
  const handleClose = () => setModalOpen(false);
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <>
      <Grid container spacing={2} direction="row" alignItems="center">
        <Grid item>
          <Typography variant="h3">{title}</Typography>
        </Grid>
        {showTips && (
          <Grid item>
            <InfoButton
              onClick={() => {
                handleOpen();
                window.gtag("event", "user_engagement_strategy_plan", {
                  event_category: "field_not_complete",
                  event_label: `${pathname?.split("/").pop() || "plan"} - ${
                    planId || "Not set"
                  } - ${userDetails()}`,
                });
              }}
            >
              Tips
            </InfoButton>
          </Grid>
        )}
      </Grid>
      <Typography
        variant="subtitle1"
        sx={{ mt: 1, maxWidth: 700, color: alpha(primaryColors.eggplant, 0.7) }}
      >
        {details}
      </Typography>
      {endDivider && <Divider sx={{ mt: 3 }} />}
      <Dialog
        open={modalOpen}
        onClose={handleClose}
        fullScreen={fullScreen}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <DialogTitle sx={{ p: 4 }}>
          <Grid container justifyContent="flex-end">
            <IconButton onClick={handleClose}>
              <CloseRoundedIcon />
            </IconButton>
          </Grid>
          <div>
            <Typography variant="h6" sx={{ fontWeight: 700 }}>
              Tips
            </Typography>
            <Typography variant="h3" sx={{ mb: 2, fontSize: 26 }}>
              {tipsTitle}
            </Typography>
          </div>
          <Divider />
        </DialogTitle>
        <DialogContent sx={{ p: 4 }}>
          {tipsContent}
          {/* <Typography variant="h3" sx={{ mt: 5, mb: 2, fontSize: 18 }}>
            Need more help?
          </Typography>
          <Typography variant="subtitle1" sx={{ mb: 6 }}>
            Speak to a Cascade strategy expert — 100% free. This would just be
            an introductory call to learn more about your vision and how we can
            convert that into a strategy that your team can execute.
          </Typography>
          <Button href="https://www.cascade.app/book-demo">Book a time</Button> */}
        </DialogContent>
      </Dialog>
    </>
  );
};

export default PlanDetails;
