import { Box, Checkbox, Popover, Stack } from "@mui/material";
import React from "react";
import DatePicker from "./DatePicker";
import DateRangeButton from "./DateRangeButton";
import DateRangePicker from "./DateRangePicker";

const MAX_TIMESTAMP = "9999-12-31T23:59:59.999999999Z";

const DatePickerSelector = ({ from, to, setTo, setFrom, ...props }) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [checked, setChecked] = React.useState(false);
  const DateRangeButtonRef = React.useRef();

  const handleClick = (event) => {
    setAnchorEl(DateRangeButtonRef.current);
  };

  const handleChange = (event) => {
    if (event.target.checked) {
      setTo(new Date(MAX_TIMESTAMP));
    } else {
      setTo(null);
    }
    setChecked(event.target.checked);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  React.useEffect(() => {
    if (to) {
      if (to.getTime() === new Date(MAX_TIMESTAMP).getTime()) {
        if (from) handleClose();
        setChecked(true);
      } else {
        setChecked(false);
        handleClose();
      }
    }
  }, [to, from]);

  const open = Boolean(anchorEl);
  return (
    <div>
      <DateRangeButton
        ref={DateRangeButtonRef}
        handleClick={handleClick}
        from={from}
        to={to}
        hasEndDate={!checked}
        {...props}
      />
      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        <Box sx={{ p: 1, pt: 2 }}>
          {checked ? (
            <DatePicker
              selectedDay={from}
              setSelectedDay={setFrom}
              numberOfMonths={2}
            />
          ) : (
            <DateRangePicker
              setFrom={setFrom}
              setTo={setTo}
              to={to}
              from={from}
            />
          )}

          <Stack direction="row" justifyContent="flex-end" alignItems="center">
            <Checkbox checked={checked} onChange={handleChange} />
            No end date
          </Stack>
        </Box>
      </Popover>
    </div>
  );
};

export default DatePickerSelector;
