import { primaryColors } from "@Common/tokens/colors";
import { Typography } from "@mui/material";
import React from "react";

const TipsProjectContent = () => {
  return (
    <div>
      <Typography variant="h3" sx={{ mb: 2, fontSize: 18 }}>
        What is a Project?
      </Typography>
      <Typography
        variant="subtitle1"
        color={primaryColors.eggplant}
        sx={{ mb: 2 }}
      >
        Every great strategy has three fundamental parts: Goals, Actions and
        Metrics. Thus far, you have set up your Goals. Now, it’s time to set up
        your Actions and decide how you’re going to achieve those Goals.
      </Typography>
      <Typography
        variant="subtitle1"
        color={primaryColors.eggplant}
        sx={{ mb: 5 }}
      >
        Every Project aligns with one or more Strategic Objectives and takes you
        closer to achieving them.
      </Typography>

      <Typography variant="h3" sx={{ mt: 5, mb: 2, fontSize: 18 }}>
        How to write a project
      </Typography>
      <Typography variant="subtitle1" sx={{ mb: 5 }}>
        <span style={{ color: "#3232FF" }}>Action (Verb)</span> +
        <span style={{ color: "#FF9902" }}> Detail</span> +
        <span style={{ color: "#9900FF" }}> Deadline</span>
        <br />
        Ex. <span style={{ color: "#3232FF" }}>Create </span>
        <span style={{ color: "#FF9902" }}>
          the standard for quality bikes{" "}
        </span>
        <span style={{ color: "#9900FF" }}>by 31st of December 2022</span>
      </Typography>
    </div>
  );
};

export default TipsProjectContent;
