import FormTextInput from "@Common/components/react-hook-form-ui/FormTextInput";
import { detailsValidation } from "@Strategy-planning/data/validations";
import FormContainer from "@Strategy-planning/layouts/FormContainer";
import StepperLayout from "@Strategy-planning/layouts/StepperLayout/StepperLayout";
import React from "react";

const formData = {
  validationSchema: detailsValidation,
};

const detailsData = {
  title: "Create a new strategic plan for",
  details: "Enter your company name",
  endDivider: false,
  showTips: false,
};

const headerData = {
  showCompanyName: false,
};

const Details = ({ initialValues }) => {
  return (
    <div>
      <FormContainer
        initialValues={initialValues}
        validationSchema={formData.validationSchema}
      >
        <StepperLayout
          headerData={headerData}
          detailsData={detailsData}
          maxWidth="sm"
          submitButtonJustify="flex-start"
        >
          <FormTextInput
            name="companyName"
            placeholder="Cascade Strategy"
            fullWidth
          />
        </StepperLayout>
      </FormContainer>
    </div>
  );
};

export default Details;
