import { get } from "lodash";
import React from "react";
import { useFormContext, useWatch } from "react-hook-form";
import DatePickerSelector from "../Calendar/DatePickerSelector";

const FormDateRangeSelect = ({ name, ...otherProps }) => {
  const startDateName = `${name}.startDate`;
  const endDateName = `${name}.endDate`;
  const {
    control,
    setValue,
    formState: { errors, isSubmitted },
  } = useFormContext();

  const from = useWatch({
    name: startDateName,
    control: control,
  });

  const to = useWatch({
    name: endDateName,
    control: control,
  });

  const setFrom = (val) => {
    setValue(startDateName, val, { shouldValidate: isSubmitted });
  };
  const setTo = (val) => {
    setValue(endDateName, val, { shouldValidate: isSubmitted });
  };
  const fromError = get(errors, startDateName)?.message;
  const toError = get(errors, endDateName)?.message;

  return (
    <div>
      <DatePickerSelector
        fromError={fromError}
        toError={toError}
        to={to}
        from={from}
        setFrom={setFrom}
        setTo={setTo}
        {...otherProps}
      />
    </div>
  );
};

export default FormDateRangeSelect;
