import { IconButton } from "@mui/material";
import React from "react";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import FormEditMenu from "./FormEditMenu";

const EditButton = (props) => {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <IconButton onClick={handleClick} sx={props.sx}>
        <MoreHorizIcon />
      </IconButton>
      <FormEditMenu anchorEl={anchorEl} {...props} handleClose={handleClose} />
    </>
  );
};

export default EditButton;
