import FormTextInput from "@Common/components/react-hook-form-ui/FormTextInput";
import { Box, Button, Grid, IconButton } from "@mui/material";
import { objectiveValidation } from "@Strategy-planning/data/validations";
import FormContainer from "@Strategy-planning/layouts/FormContainer";
import StepperLayout from "@Strategy-planning/layouts/StepperLayout/StepperLayout";
import { SPT_FIELD_NAME } from "@Strategy-planning/tokens/fieldNameToken";
import React from "react";
import { useFieldArray, useFormContext } from "react-hook-form";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import AddButton from "@Common/components/AddButton";
import { defaultObjective } from "@Strategy-planning/data/defaults";
import HeaderFocusArea from "./components/HeaderFocusArea";
import FormFreesoloSelect from "@Common/components/react-hook-form-ui/FormFreesoloSelect";
import FormDateRangeSelect from "@Common/components/react-hook-form-ui/FormDateRangeSelect";
import TipsObjectiveContent from "./Plan/tipsLayout/TipsObjectiveContent";

const formData = {
  validationSchema: objectiveValidation,
};

const detailsData = {
  title: "Choose your objectives for each focus area",
  details:
    "An Objective is something specific that will help you to actualize one or more of your Focus Areas. Unlike Focus Areas, your Objectives should be specific, and contain a deadline where possible.",
  tipsTitle: "Objective",
  tipsContent: <TipsObjectiveContent />,
};

const ObjectivesFields = ({ name }) => {
  const fieldName = `${name}[${SPT_FIELD_NAME.OBJECTIVE}]`;
  const { control } = useFormContext();
  const { fields, append, remove } = useFieldArray({
    control,
    name: fieldName,
  });
  const hasOneField = fields.length === 1;
  return (
    <>
      {fields.map((value, index) => (
        <div key={value.id}>
          <Grid container spacing={1} alignItems="center" sx={{ mt: 1, mb: 1 }}>
            <Grid
              item
              sx={{
                width: { xs: "100%", md: 200 },
                flexGrow: { xs: 1, sm: 0 },
              }}
            >
              <FormFreesoloSelect
                name={`${fieldName}[${index}].verb`}
                textfieldProps={{ placeholder: "Select verb" }}
              />
            </Grid>
            <Grid item sx={{ flexGrow: 1 }}>
              <FormTextInput
                name={`${fieldName}[${index}].title`}
                placeholder="Add an objective"
                fullWidth
              />
            </Grid>
            <Grid item sx={{ width: { xs: "100%", md: 300 } }}>
              <FormDateRangeSelect
                name={`${fieldName}[${index}]`}
                // isTitle={true}
              />
            </Grid>
            <Grid
              item
              sx={{ width: { xs: "100%", md: 45 } }}
              order={{ xs: 6, lg: 6 }}
            >
              {!hasOneField && (
                <>
                  <Box
                    sx={{
                      display: { xs: "none", md: "inherit" },
                    }}
                  >
                    <IconButton onClick={() => remove(index)}>
                      <CloseRoundedIcon />
                    </IconButton>
                  </Box>
                  <Box
                    sx={{
                      display: { xs: "block", md: "none" },
                    }}
                  >
                    <Button
                      variant="outlined"
                      color="error"
                      fullWidth
                      onClick={() => remove(index)}
                    >
                      Delete KPI
                    </Button>
                  </Box>
                </>
              )}
            </Grid>
          </Grid>
        </div>
      ))}
      <AddButton onClick={() => append(defaultObjective)}>
        Add Objective
      </AddButton>
    </>
  );
};

const Objectives = ({ initialValues }) => {
  React.useEffect(() => {
    document.title = "Objective - strategy-planning-tool";
  }, []);
  return (
    <div>
      <FormContainer
        initialValues={initialValues}
        validationSchema={formData.validationSchema}
      >
        <StepperLayout detailsData={detailsData} maxWidth="lg">
          <HeaderFocusArea>
            {(focusAreaName) => <ObjectivesFields name={focusAreaName} />}
          </HeaderFocusArea>
        </StepperLayout>
      </FormContainer>
    </div>
  );
};

export default Objectives;
