import { MenuItem, TextField } from "@mui/material";
import React from "react";

const YearMonthForm = React.memo(
  ({ date, localeUtils, onChange, fromInit, toInit }) => {
    const currentYear = new Date().getFullYear();
    const fromMonth =
      fromInit || new Date((toInit?.getFullYear() || currentYear) - 10, 0);
    const toMonth =
      toInit || new Date((fromInit?.getFullYear() || currentYear) + 10, 11);
    const months = localeUtils.getMonths();

    const disableMonthIndex =
      fromInit && date.getFullYear() === fromInit.getFullYear()
        ? fromInit.getMonth()
        : toInit && date.getFullYear() === toInit.getFullYear()
        ? toInit.getMonth()
        : null;

    const years = [];
    for (let i = fromMonth.getFullYear(); i <= toMonth.getFullYear(); i += 1) {
      years.push(i);
    }

    const handleMonthChange = (e) => {
      onChange(new Date(date.getFullYear(), e.target.value));
    };

    const handleYearChange = (e) => {
      const month =
        e.target.value === fromInit?.getFullYear()
          ? fromInit.getMonth() + 1
          : date.getMonth();
      console.log(disableMonthIndex, month);
      onChange(new Date(e.target.value, month));
    };

    return (
      <div className="DayPicker-Caption">
        <TextField
          variant="standard"
          name="month"
          onChange={handleMonthChange}
          value={date.getMonth()}
          select
          sx={{ mr: 2 }}
        >
          {months.map((month, i) => (
            <MenuItem
              sx={{
                pt: 0,
                pd: 0,
              }}
              key={month}
              value={i}
              disabled={
                disableMonthIndex !== null &&
                (fromInit ? disableMonthIndex >= i : disableMonthIndex < i)
              }
            >
              {month.slice(0, 3)}
            </MenuItem>
          ))}
        </TextField>
        <TextField
          select
          name="year"
          variant="standard"
          onChange={handleYearChange}
          value={date.getFullYear()}
        >
          {years.map((year) => (
            <MenuItem key={year} value={year}>
              {year}
            </MenuItem>
          ))}
        </TextField>
      </div>
    );
  },
  (oldProps, newProps) =>
    !!(oldProps.date.getTime() === newProps.date.getTime())
);

export default YearMonthForm;
