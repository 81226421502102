import { Grid, LinearProgress, Typography } from "@mui/material";
import React from "react";
import { ReactComponent as CascadeToolsIcon } from "../assets/cascade-tools.svg";

const LoadingElement = ({ children }) => {
  return (
    <Grid
      container
      spacing={0}
      direction="column"
      alignItems="center"
      justifyContent="center"
      style={{ minHeight: "100vh" }}
    >
      <Grid item xs={3} textAlign="center">
        <CascadeToolsIcon />
        <LinearProgress color="primary" />
        <Typography variant="h6" sx={{ mt: "3px" }}>
          {children}
        </Typography>
      </Grid>
    </Grid>
  );
};

export default LoadingElement;
