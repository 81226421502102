import FormTextInput from "@Common/components/react-hook-form-ui/FormTextInput";
import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Typography,
} from "@mui/material";
import EditButton from "@Strategy-planning/components/EditButton";
import { SPT_FIELD_NAME } from "@Strategy-planning/tokens/fieldNameToken";
import React from "react";
import { useFormContext } from "react-hook-form";
import { ValuesFields } from "../Values";

import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import CloseIcon from "@mui/icons-material/Close";

const ValuesForm = () => {
  const { getValues } = useFormContext();
  const valuesField = getValues(SPT_FIELD_NAME.VALUE);
  return (
    <>
      {valuesField.map((item, index) => {
        return (
          <Grid item xs={4} key={index}>
            <FormTextInput
              name={`${SPT_FIELD_NAME.VALUE}[${index}].title`}
              placeholder="Values"
              fullWidth
              inputProps={{ sx: { textAlign: "center" } }}
            />
          </Grid>
        );
      })}
    </>
  );
};

const VisionValuesHeader = () => {
  const [showTitle, setShowTitle] = React.useState(true);
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  return (
    <>
      {showTitle ? (
        <Box sx={{ bgcolor: "white", p: "5px 20px 20px 20px" }}>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-end",
            }}
          >
            <EditButton showIcons={false} handleEdit={handleOpen} />
            <IconButton onClick={() => setShowTitle(!showTitle)}>
              {showTitle ? <VisibilityIcon /> : <VisibilityOffIcon />}
            </IconButton>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              marginBottom: "10px",
            }}
          >
            <FormTextInput
              name="vision"
              autosize
              variant="standard"
              placeholder="Project Title"
              inputProps={{
                sx: {
                  fontSize: "20px",
                  fontWeight: 600,
                },
              }}
            />
          </div>
          <Grid container direction="row" spacing={2} justifyContent="center">
            <ValuesForm />
          </Grid>
        </Box>
      ) : (
        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            paddingRight: "20px",
            paddingTop: "5px",
          }}
        >
          <IconButton onClick={() => setShowTitle(!showTitle)}>
            {showTitle ? <VisibilityIcon /> : <VisibilityOffIcon />}
          </IconButton>
        </div>
      )}
      <Dialog open={open} maxWidth="sm" fullWidth>
        <Box sx={{ p: 2 }}>
          <DialogTitle>
            <IconButton
              aria-label="close"
              onClick={handleClose}
              sx={{
                position: "absolute",
                right: 8,
                top: 8,
                color: (theme) => theme.palette.grey[500],
              }}
            >
              <CloseIcon />
            </IconButton>
          </DialogTitle>
          <DialogContent>
            <Box p={2}>
              <Typography sx={{ fontSize: "26px", fontWeight: 700, mb: 2 }}>
                Vision
              </Typography>
              <FormTextInput
                name="vision"
                fullWidth
                multiline
                minRows={3}
                maxRows={5}
                placeholder="Vision"
              />
              <Typography
                sx={{ fontSize: "26px", fontWeight: 700, mt: 4, mb: 2 }}
              >
                Values
              </Typography>
              <ValuesFields />
            </Box>
          </DialogContent>
        </Box>
      </Dialog>
    </>
  );
};

export default VisionValuesHeader;
