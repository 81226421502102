import FormTextInput from "@Common/components/react-hook-form-ui/FormTextInput";
import { Box, Button, Chip, Divider, Grid, IconButton } from "@mui/material";
import { kpiValidation } from "@Strategy-planning/data/validations";
import FormContainer from "@Strategy-planning/layouts/FormContainer";
import StepperLayout from "@Strategy-planning/layouts/StepperLayout/StepperLayout";
import { SPT_FIELD_NAME } from "@Strategy-planning/tokens/fieldNameToken";
import React from "react";
import { useFieldArray, useFormContext } from "react-hook-form";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import AddButton from "@Common/components/AddButton";
import { defaultObjective } from "@Strategy-planning/data/defaults";
import HeaderFocusArea from "./components/HeaderFocusArea";
import FormFreesoloSelect from "@Common/components/react-hook-form-ui/FormFreesoloSelect";
import FormDateRangeSelect from "@Common/components/react-hook-form-ui/FormDateRangeSelect";
import HeaderObjective from "./components/HeaderObjective";
import TipsKPIContent from "./Plan/tipsLayout/TipsKPIContent";
import { unitOptions } from "@Strategy-planning/tokens/dropDownOptions";

const formData = {
  validationSchema: kpiValidation,
};

const detailsData = {
  title: "What are your KPIs for your objectives?",
  details:
    "Each of your Objectives should have at least one KPI. KPIs will help you determine whether or not you're achieving that goal. Check out our KPI guide and library for KPI help and examples.",
  tipsTitle: "KPI",
  tipsContent: <TipsKPIContent />,
};

const KPIFields = ({ name }) => {
  const fieldName = `${name}.${SPT_FIELD_NAME.KPI}`;
  const { control } = useFormContext();
  const { fields, append, remove } = useFieldArray({
    control,
    name: fieldName,
  });
  const hasOneField = fields.length === 1;
  return (
    <>
      {fields.map((value, index) => (
        <div key={value.id}>
          {console.log(fieldName)}
          <Box
            sx={{ mt: { xs: 2, md: 0 }, display: { xs: "block", md: "none" } }}
          >
            <Divider>
              <Chip label="KPI" sx={{ background: "#DCF9DA" }} />
            </Divider>
          </Box>
          <Grid container spacing={1} alignItems="center" sx={{ mt: 1, mb: 1 }}>
            <Grid
              item
              sx={{
                width: { xs: "100%", md: 160 },
                flexGrow: { xs: 1, lg: 0 },
              }}
              order={{ md: 1, lg: 1 }}
            >
              <FormFreesoloSelect
                name={`${fieldName}[${index}].verb`}
                textfieldProps={{
                  placeholder: "Select verb",
                }}
              />
            </Grid>
            <Grid
              item
              sx={{ flexGrow: 1, flexBasis: { xs: "50%", lg: "0%" } }}
              order={{ sm: 5, lg: 2 }}
            >
              <FormTextInput
                name={`${fieldName}[${index}].title`}
                placeholder="A new platform for interacting with our customers"
                fullWidth
              />
            </Grid>
            <Grid
              item
              sx={{
                width: { xs: "100%", md: 110, lg: 130 },
                flexGrow: { xs: 1, lg: 0 },
              }}
              order={{ sm: 2, lg: 3 }}
            >
              <FormTextInput
                name={`${fieldName}[${index}].metric`}
                placeholder="metric"
                fullWidth
                type="number"
              />
            </Grid>
            <Grid
              item
              sx={{
                width: { xs: "100%", md: 150, lg: 150 },
                flexGrow: { xs: 1, lg: 0 },
                // flexBasis: { sm: "50%", md: "unset" },
              }}
              order={{ xs: 4, md: 3, lg: 4 }}
            >
              <FormFreesoloSelect
                name={`${fieldName}[${index}].unit`}
                textfieldProps={{
                  placeholder: "unit",
                }}
                options={unitOptions}
              />
            </Grid>
            <Grid
              item
              sx={{ width: { xs: "100%", md: 300, lg: 300 } }}
              order={{ xs: 5, md: 4, lg: 5 }}
            >
              <FormDateRangeSelect name={`${fieldName}[${index}]`} />
            </Grid>

            <Grid
              item
              sx={{ width: { xs: "100%", md: 45 } }}
              order={{ xs: 6, lg: 6 }}
            >
              {!hasOneField && (
                <>
                  <Box
                    sx={{
                      display: { xs: "none", md: "inherit" },
                    }}
                  >
                    <IconButton onClick={() => remove(index)}>
                      <CloseRoundedIcon />
                    </IconButton>
                  </Box>
                  <Box
                    sx={{
                      display: { xs: "block", md: "none" },
                    }}
                  >
                    <Button
                      variant="outlined"
                      color="error"
                      fullWidth
                      onClick={() => remove(index)}
                    >
                      Delete KPI
                    </Button>
                  </Box>
                </>
              )}
            </Grid>
          </Grid>
        </div>
      ))}
      <AddButton onClick={() => append(defaultObjective)}>Add KPI</AddButton>
    </>
  );
};

const KPIs = ({ initialValues }) => {
  return (
    <div>
      {/* <Helmet>
        <title>KPI - strategy planning tool</title>
        <meta
          name="description"
          content="KPI page for strategy planning tool"
        />
      </Helmet> */}
      <FormContainer
        initialValues={initialValues}
        validationSchema={formData.validationSchema}
      >
        <StepperLayout detailsData={detailsData} maxWidth="lg">
          <HeaderFocusArea>
            {(focusAreaName) => (
              <HeaderObjective name={focusAreaName}>
                {(objectiveName) => <KPIFields name={objectiveName} />}
              </HeaderObjective>
            )}
          </HeaderFocusArea>
        </StepperLayout>
      </FormContainer>
    </div>
  );
};

export default KPIs;
