import {
  detailsValidation,
  focusAreaValidation,
  kpiValidation,
  objectiveValidation,
  projectValidation,
  validateAll,
  valuesValidation,
  visionValidation,
} from "@Strategy-planning/data/validations";

export const SPT_PATHS = {
  DETAILS: "details",
  VISION: "vision",
  VALUES: "values",
  FOCUS_AREA: "focus-areas",
  OBJECTVIES: "objectives",
  PROJECT: "projects",
  KPIS: "KPI",
  PLAN: "plan",
};

export const SPT_PATH_ORDER = [
  {
    title: "Details",
    route: SPT_PATHS.DETAILS,
    fullPath: `../${SPT_PATHS.DETAILS}`,
    validate: detailsValidation,
  },
  {
    title: "Vision",
    route: SPT_PATHS.VISION,
    fullPath: `../${SPT_PATHS.VISION}`,
    validate: visionValidation,
  },
  {
    title: "Values",
    route: SPT_PATHS.VALUES,
    fullPath: `../${SPT_PATHS.VALUES}`,
    validate: valuesValidation,
  },
  {
    title: "Focus area",
    route: SPT_PATHS.FOCUS_AREA,
    fullPath: `../${SPT_PATHS.FOCUS_AREA}`,
    validate: focusAreaValidation,
  },
  {
    title: "Objectives",
    route: SPT_PATHS.OBJECTVIES,
    fullPath: `../${SPT_PATHS.OBJECTVIES}`,
    validate: objectiveValidation,
  },
  {
    title: "Projects",
    route: SPT_PATHS.PROJECT,
    fullPath: `../${SPT_PATHS.PROJECT}`,
    validate: projectValidation,
  },
  {
    title: "KPI",
    route: SPT_PATHS.KPIS,
    fullPath: `../${SPT_PATHS.KPIS}`,
    validate: kpiValidation,
  },
  {
    title: "Plan",
    route: SPT_PATHS.PLAN,
    fullPath: `../${SPT_PATHS.PLAN}`,
    validate: validateAll,
  },
];
