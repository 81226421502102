import FormDateRangeSelect from "@Common/components/react-hook-form-ui/FormDateRangeSelect";
import FormFreesoloSelect from "@Common/components/react-hook-form-ui/FormFreesoloSelect";
import FormTextInput from "@Common/components/react-hook-form-ui/FormTextInput";
import { Box, Divider, Grid, Typography } from "@mui/material";
import HeaderTitle from "@Strategy-planning/components/HeaderTitle";
import { defaultFocusArea } from "@Strategy-planning/data/defaults";
import { SPT_FIELD_NAME } from "@Strategy-planning/tokens/fieldNameToken";
import React from "react";
import { useFieldArray, useFormContext } from "react-hook-form";

const ObjectiveFields = ({
  name,
  calendarBreakPoint = "md",
  ...otherProps
}) => {
  return (
    <div>
      <HeaderTitle variant="objective" {...otherProps}>
        Objective
      </HeaderTitle>
      <Grid container alignItems="center">
        {/* <Grid item xs={6}>
          <Stack direction="row"> */}
        <Grid
          item
          sx={{ maxWidth: { xs: "30%", md: 150 } }}
          // sx={{ width: 160 }}
        >
          {/* <Stack direction="row"> */}
          <FormFreesoloSelect
            name={`${name}.verb`}
            textfieldProps={{
              placeholder: "Select verb",
              autosize: true,
              variant: "standard",
              InputProps: {
                sx: { fontSize: "20px", fontWeight: 700 },
                // endAdornment: (
                //   // <InputAdornment position="start">
                //   <Typography variant="h5" sx={{ ml: "2px" }}>
                //     :
                //   </Typography>
                //   // </InputAdornment>
                // ),
              },
            }}
            optionStyles={{ fontSize: "18px" }}
          />
        </Grid>
        <Typography variant="h5" sx={{ mb: 0, p: 0 }}>
          :
        </Typography>
        <Grid
          item
          sx={{
            width: "120px",
            flexGrow: 1,
            // flexBasis: "60% !important",
          }}
        >
          <FormTextInput
            name={`${name}.title`}
            placeholder="Add an objective"
            variant="standard"
            autosize
            inputProps={{ sx: { fontSize: "20px", fontWeight: 700 } }}
            sx={{ ml: "6px" }}
            // fullWidth
          />
          {/* </Stack> */}
        </Grid>
        {/* <Box sx={{ flexGrow: 1 }} /> */}
        <Grid item sx={{ width: { xs: "100%", sm: 300 } }}>
          <Box
            sx={{
              display: { xs: "flex", [calendarBreakPoint]: "none" },
              justifyContent: "flex-start",
            }}
          >
            <FormDateRangeSelect
              isTitle
              noIconTitle
              name={name}
              fontStyles={{ fontWeight: 700 }}
            />
          </Box>
          <Box
            sx={{
              display: { xs: "none", [calendarBreakPoint]: "inherit" },

              ml: "4px",
            }}
          >
            <FormDateRangeSelect
              isTitle
              name={name}
              fontStyles={{ fontWeight: 700 }}
            />
          </Box>
        </Grid>
        <Grid item sx={{ width: { xs: 0, [calendarBreakPoint]: 45 } }}></Grid>
      </Grid>
    </div>
  );
};

const HeaderObjective = ({ name, children }) => {
  const fieldName = `${name}.${SPT_FIELD_NAME.OBJECTIVE}`;
  const { control } = useFormContext();
  const { fields, remove, insert } = useFieldArray({
    control,
    name: fieldName,
  });

  const hasOneField = fields.length === 1;
  return (
    <>
      {fields.map((value, index) => {
        const handleFunctions = {
          handleAddAbove: () => {
            insert(index, defaultFocusArea);
          },

          handleAddBelow: () => {
            insert(index + 1, defaultFocusArea);
          },
          handleDelete: !hasOneField
            ? () => {
                remove(index);
              }
            : null,
        };
        return (
          <div key={value.id}>
            <Grid container direction="row" spacing={2.5}>
              <Grid item>
                <Divider
                  orientation="vertical"
                  sx={{
                    borderRightWidth: 4,
                    borderRadius: 1,
                    height: "calc(100% - 66.4px)",
                  }}
                />
              </Grid>
              <Grid item xs>
                <ObjectiveFields
                  name={`${fieldName}[${index}]`}
                  {...handleFunctions}
                />
                {children(`${fieldName}[${index}]`)}
              </Grid>
            </Grid>
          </div>
        );
      })}
    </>
  );
};

export default HeaderObjective;
