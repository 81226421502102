// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import {
  getAuth,
  GoogleAuthProvider,
  signInWithPopup,
  signOut,
} from "firebase/auth";

import { getFunctions } from "firebase/functions";
import { removeUserToken } from "./storage/authTokenStorage";
import { removeAnonymousToken } from "./storage/anonymousTokenStorage";

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);

export const db = getFirestore(app);

const provider = new GoogleAuthProvider();

export const signInWithGoogle = () => signInWithPopup(auth, provider);

export const userSignOut = async () => {
  await signOut(auth);
  removeUserToken();
  removeAnonymousToken();
};

const functionsConfig = {
  apiKey: process.env.REACT_APP_F_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_F_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_F_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_F_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_F_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_F_FIREBASE_APP_ID,
  measurementId: process.env.REACT_APP_F_FIREBASE_MEASUREMENT_ID,
};

const funcApp = initializeApp(functionsConfig, "secondary");
export const functions = getFunctions(funcApp);
// connectFunctionsEmulator(functions, "localhost", 5001);
