import FormTextInput from "@Common/components/react-hook-form-ui/FormTextInput";
import { IconButton, Stack, Typography } from "@mui/material";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
} from "@Strategy-planning/components/Accordion";
import { SPT_FIELD_NAME } from "@Strategy-planning/tokens/fieldNameToken";
import React from "react";
import { useFieldArray, useFormContext } from "react-hook-form";
import RowTemplate from "./components/RowTemplate";

import ArrowForwardIosSharpIcon from "@mui/icons-material/ArrowForwardIosSharp";
import FormFreesoloSelect from "@Common/components/react-hook-form-ui/FormFreesoloSelect";
import FormDateRangeSelect from "@Common/components/react-hook-form-ui/FormDateRangeSelect";
import ProjectBody from "./ProjectBody";
import KPIBody from "./KPIBody";
import { defaultObjective } from "@Strategy-planning/data/defaults";

const ObjectiveFields = ({
  toggleAcordion,
  index,
  expand,
  name,
  arrayHelper,
}) => {
  return (
    <RowTemplate
      aria-label="ObjectiveHeader"
      {...arrayHelper}
      index={index}
      defaultVal={defaultObjective}
      title={
        <Stack direction="row" sx={{ pl: 1 }} alignItems="center">
          <IconButton onClick={toggleAcordion}>
            <ArrowForwardIosSharpIcon
              sx={{
                fontSize: "0.8rem",
                transform: expand && "rotate(90deg)",
              }}
            />
          </IconButton>
          <FormFreesoloSelect
            name={`${name}.verb`}
            textfieldProps={{
              variant: "standard",
              autosize: true,
              placeholder: "Select verb",
              InputProps: { sx: { fontSize: "14px", fontWeight: 600 } },
            }}
            showTooltip={false}
          />
          <Typography
            sx={{ fontSize: "15px", fontWeight: 600, mr: 1, mb: "5px" }}
          >
            :
          </Typography>
          <FormTextInput
            name={`${name}.title`}
            autosize
            variant="standard"
            placeholder="Objective Title"
            inputProps={{ sx: { fontSize: "14px", fontWeight: 600 } }}
            showTooltip={false}
          />
        </Stack>
      }
      date={
        <FormDateRangeSelect
          noIconTitle
          fontStyles={{ fontSize: "14px", fontWeight: 600 }}
          name={name}
          showTooltip={false}
        />
      }
    />
  );
};

const ObjectiveBody = ({ name, showDate }) => {
  const [expand, setExpand] = React.useState(true);
  const toggleAcordion = () => {
    setExpand((prev) => !prev);
  };
  const fieldName = `${name}.${SPT_FIELD_NAME.OBJECTIVE}`;
  const { control } = useFormContext();
  const { fields, ...arrayHelper } = useFieldArray({
    control,
    name: fieldName,
  });

  return (
    <div>
      {fields.map((value, index) => {
        return (
          <div key={value.id}>
            <Accordion expanded={expand}>
              <AccordionSummary
                //   IconButtonProps={{
                //     onClick: toggleAcordion,
                //   }}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <ObjectiveFields
                  expand={expand}
                  index={index}
                  toggleAcordion={toggleAcordion}
                  name={`${fieldName}[${index}]`}
                  arrayHelper={arrayHelper}
                />
              </AccordionSummary>
              <AccordionDetails>
                <ProjectBody
                  name={`${fieldName}[${index}]`}
                  showDate={showDate}
                />
                <KPIBody name={`${fieldName}[${index}]`} showDate={showDate} />
              </AccordionDetails>
            </Accordion>
          </div>
        );
      })}
    </div>
  );
};

export default ObjectiveBody;
