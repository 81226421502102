import React from "react";
import { auth } from "@Common/firebase";
import { getAnonymousToken } from "@Common/storage/anonymousTokenStorage";
import { Button } from "@mui/material";
import { planOnSubmit } from "@Strategy-planning/utils/planOnSubmit";
import { useSnackbar } from "notistack";
import { useFormContext } from "react-hook-form";
import { useLocation, useNavigate, useParams } from "react-router-dom";
const sleep = (time) => new Promise((acc) => setTimeout(acc, time));

const userDetails = () => {
  if (getAnonymousToken()) return "Unknown";
  return auth.currentUser.uid;
};

const SubmitButton = React.forwardRef(
  (
    {
      nextPage,
      nextFunction,
      label = "Continue",
      clickable = true,
      ...otherProps
    },
    ref
  ) => {
    const { pathname } = useLocation();
    const navigate = useNavigate();
    const { enqueueSnackbar } = useSnackbar();
    const { handleSubmit, trigger } = useFormContext();
    const { planId } = useParams();

    const gaValue = () => {
      return `${pathname?.split("/").pop() || "plan"} - ${
        planId || "Not set"
      } - ${userDetails()}`;
    };

    const gaNextPageValue = () => {
      return `${nextPage?.split("/").pop() || "plan"} - ${
        planId || "Not set"
      } - ${userDetails()}`;
    };
    return (
      <Button
        ref={ref}
        variant="contained"
        onClick={
          clickable
            ? async () => {
                await handleSubmit(async (data) => {
                  await sleep(1000);
                  const result = await planOnSubmit(
                    data,
                    planId,
                    nextPage,
                    gaNextPageValue
                  );
                  if (result) {
                    enqueueSnackbar("Plan saved successfully", {
                      variant: "success",
                    });
                  } else {
                    enqueueSnackbar("Sever error has occured!", {
                      variant: "error",
                    });
                  }
                  if (nextPage) navigate(nextPage);
                  if (nextFunction) {
                    await nextFunction(data);
                    enqueueSnackbar("File will download now", {
                      variant: "success",
                    });
                  }
                })();
                if (!(await trigger())) {
                  window.gtag("event", "user_engagement_strategy_plan", {
                    event_category: "field_not_complete",
                    event_label: gaValue(),
                  });
                }
              }
            : () => {}
        }
        {...otherProps}
      >
        {label}
      </Button>
    );
  }
);

export default SubmitButton;
