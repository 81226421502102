import FormTextInput from "@Common/components/react-hook-form-ui/FormTextInput";
import { Box, Button, Grid, IconButton } from "@mui/material";
import { projectValidation } from "@Strategy-planning/data/validations";
import FormContainer from "@Strategy-planning/layouts/FormContainer";
import StepperLayout from "@Strategy-planning/layouts/StepperLayout/StepperLayout";
import { SPT_FIELD_NAME } from "@Strategy-planning/tokens/fieldNameToken";
import React from "react";
import { useFieldArray, useFormContext } from "react-hook-form";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import AddButton from "@Common/components/AddButton";
import { defaultObjective } from "@Strategy-planning/data/defaults";
import HeaderFocusArea from "./components/HeaderFocusArea";
import FormFreesoloSelect from "@Common/components/react-hook-form-ui/FormFreesoloSelect";
import FormDateRangeSelect from "@Common/components/react-hook-form-ui/FormDateRangeSelect";
import HeaderObjective from "./components/HeaderObjective";
import { Helmet } from "react-helmet-async";
import TipsProjectContent from "./Plan/tipsLayout/TipsProjectContent";

const formData = {
  validationSchema: projectValidation,
};

const detailsData = {
  title: "What projects do you need to complete?",
  details:
    "A Project is the specific thing that you're going to DO to deliver against your Objectives. Projects must be very specific and involve a tangible (non metric-based) deliverable. You should aim for at least two Projects for each of your Objectives",
  tipsTitle: "Project",
  tipsContent: <TipsProjectContent />,
};

const ProjectFields = ({ name }) => {
  const fieldName = `${name}.${SPT_FIELD_NAME.PROJECT}`;
  const { control } = useFormContext();
  const { fields, append, remove } = useFieldArray({
    control,
    name: fieldName,
  });
  const hasOneField = fields.length === 1;
  return (
    <>
      {fields.map((value, index) => (
        <div key={value.id}>
          <Grid container spacing={1} alignItems="center" sx={{ mt: 1, mb: 1 }}>
            <Grid
              item
              sx={{
                width: { xs: "100%", md: 200 },
                flexGrow: { xs: 1, sm: 0 },
              }}
            >
              <FormFreesoloSelect
                name={`${fieldName}[${index}].verb`}
                textfieldProps={{ placeholder: "Select verb" }}
              />
            </Grid>
            <Grid item sx={{ flexGrow: 1 }}>
              <FormTextInput
                name={`${fieldName}[${index}].title`}
                placeholder="Add a project"
                fullWidth
              />
            </Grid>
            <Grid item sx={{ width: { xs: "100%", md: 300 } }}>
              <FormDateRangeSelect
                name={`${fieldName}[${index}]`}
                // isTitle={true}
              />
            </Grid>
            <Grid
              item
              sx={{ width: { xs: "100%", md: 45 } }}
              order={{ xs: 6, lg: 6 }}
            >
              {!hasOneField && (
                <>
                  <Box
                    sx={{
                      display: { xs: "none", md: "inherit" },
                    }}
                  >
                    <IconButton onClick={() => remove(index)}>
                      <CloseRoundedIcon />
                    </IconButton>
                  </Box>
                  <Box
                    sx={{
                      display: { xs: "block", md: "none" },
                    }}
                  >
                    <Button
                      variant="outlined"
                      color="error"
                      fullWidth
                      onClick={() => remove(index)}
                    >
                      Delete KPI
                    </Button>
                  </Box>
                </>
              )}
            </Grid>
          </Grid>
        </div>
      ))}
      <AddButton onClick={() => append(defaultObjective)}>
        Add Project
      </AddButton>
    </>
  );
};

const Projects = ({ initialValues }) => {
  return (
    <div>
      <Helmet>
        {/* <title>Project - strategy planning tool</title> */}
        <meta
          name="description"
          content="Project page for strategy planning tool"
        />
      </Helmet>
      <FormContainer
        initialValues={initialValues}
        validationSchema={formData.validationSchema}
      >
        <StepperLayout detailsData={detailsData} maxWidth="lg">
          <HeaderFocusArea>
            {(focusAreaName) => (
              <HeaderObjective name={focusAreaName}>
                {(objectiveName) => <ProjectFields name={objectiveName} />}
              </HeaderObjective>
            )}
          </HeaderFocusArea>
        </StepperLayout>
      </FormContainer>
    </div>
  );
};

export default Projects;
