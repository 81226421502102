import {
  Button,
  Container,
  GlobalStyles,
  Paper,
  Typography,
} from "@mui/material";
import React from "react";
import ButtonInputSelector from "./ButtonInputSelector";
import { Accordion, AccordionDetails, AccordionSummary } from "./Accordion";
import KPIList from "./KPIList";
import { primaryColors, secondaryColors } from "@Common/tokens/colors";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";

const HeaderTitle = ({ children }) => {
  return (
    <div>
      <Container maxWidth="sm" sx={{ mt: "30px" }}>
        <Typography
          variant="subtitle1"
          align="center"
          sx={{
            width: "110px",
            p: "2px 10px",
            margin: "auto",
            borderRadius: 1,
            fontSize: "11px",
            fontWeight: 700,
            color: primaryColors.white,
            background:
              "-webkit-linear-gradient(0deg, #FE754A 30%, #FFB901 90%)",
            WebkitBackgroundClip: "transparent",
            WebkitTextFillColor: "fill",
          }}
        >
          FREE RESOURCE
        </Typography>
        <Typography
          variant="h3"
          align="center"
          sx={{ mt: "10px", fontSize: "30px" }}
        >
          KPI Generator
        </Typography>
        <Typography
          variant="subtitle1"
          align="center"
          sx={{ mt: "10px", mb: 2, fontSize: "12px" }}
        >
          Get KPI examples for your industry and function
        </Typography>
        <Paper sx={{ height: "257px", background: "#594BDB" }}></Paper>
        <Paper
          sx={{
            margin: "28px",
            marginBottom: "-257px",
            padding: 2,
            background: "#0C0924",
            overflow: "visible",
            position: "relative",
            top: "-257px",
          }}
        >
          {children}
        </Paper>
        <div style={{ height: "30px" }} />
      </Container>
    </div>
  );
};

const BlogKPIGenerator = () => {
  const [functionInput, setFunctionInput] = React.useState(null);
  const [functionOpen, setFunctionOpen] = React.useState(true);
  React.useEffect(() => {
    if (functionInput) {
      setFunctionOpen(false);
      window.gtag("event", "user_engagement_kpi_generator", {
        event_category: "function",
        event_label: functionInput,
      });
    }
  }, [functionInput]);

  const [industry, setIndustry] = React.useState(null);
  const [industryOpen, setIndustryOpen] = React.useState(true);

  const [showMoreIndustries, setShowMoreIndustries] = React.useState(false);
  //   const [showMoreFunctions, setShowMoreFunctions] = React.useState(false);

  const handleSetIndustry = (item) => {
    window.gtag("event", "user_engagement_kpi_generator", {
      event_category: "industry",
      event_label: item,
    });
    setIndustry(item);
    setIndustryOpen(false);
    setFunctionInput(null);
    setFunctionOpen(true);
  };
  const handleFunctionToggle = () => {
    setFunctionOpen(!functionOpen);
  };
  const handleIndustryToggle = () => {
    setIndustryOpen(!industryOpen);
  };
  return (
    <div
    // style={{ background: "white" }}
    >
      <GlobalStyles
        styles={
          {
            //   body: { backgroundColor: "white" },
          }
        }
      />
      <HeaderTitle>
        <Accordion
          expanded={industryOpen}
          onChange={industry && handleIndustryToggle}
        >
          <AccordionSummary
            sx={{
              cursor: !industry && "default",
              // width: "300px",

              ":hover:not(.Mui-disabled)": {
                cursor: !industry && "default",
              },
            }}
            expandIcon={
              industry && <ArrowDropDownIcon style={{ color: "white" }} />
            }
          >
            <div style={{ margin: "auto", paddingBottom: "10px" }}>
              {industry && (
                <Typography
                  variant="subtitle2"
                  align="center"
                  sx={{
                    fontSize: "11px",
                    color: secondaryColors.dustyLavender,
                    margin: "auto",
                  }}
                >
                  Industry
                </Typography>
              )}
              <Typography
                variant="body1"
                sx={{ fontWeight: 700, margin: "auto" }}
                color="primary.white"
                align="center"
              >
                {industry ? industry : "Select an industry"}
              </Typography>
            </div>
            {/* <Stack direction="column" justifyContent="center" alignItems="center">
            {industry && (
              <Typography
                variant="subtitle2"
                sx={{ fontSize: "11px", color: secondaryColors.dustyLavender }}
              >
                Industry
              </Typography>
            )}
            <Typography
              variant="body1"
              sx={{ fontWeight: 700, margin: "auto" }}
              color="primary.white"
              align="center"
            >
              {industry ? industry : "Select an industry"}
            </Typography>
          </Stack> */}
          </AccordionSummary>
          <AccordionDetails>
            <ButtonInputSelector
              value={industry}
              setValue={handleSetIndustry}
              type="industry"
              isBlog={true}
              showAll={showMoreIndustries}
            />
            {!showMoreIndustries && (
              <div style={{ display: "flex", justifyContent: "center" }}>
                <Button
                  variant="text"
                  sx={{
                    color: "white",
                    fontSize: "11px",
                    textDecoration: "underline",
                    mt: 1,
                  }}
                  onClick={() => {
                    setShowMoreIndustries(!showMoreIndustries);
                  }}
                >
                  See more industries
                </Button>
              </div>
            )}
          </AccordionDetails>
        </Accordion>
        {industry && (
          <Accordion
            sx={{
              mt: 1,
            }}
            expanded={functionOpen}
            onChange={functionInput && handleFunctionToggle}
          >
            <AccordionSummary
              sx={{
                cursor: !functionInput && "default",
                ":hover:not(.Mui-disabled)": {
                  cursor: !functionInput && "default",
                },
              }}
              expandIcon={
                functionInput && (
                  <ArrowDropDownIcon style={{ color: "white" }} />
                )
              }
            >
              <div style={{ margin: "auto", paddingBottom: "10px" }}>
                {functionInput && (
                  <Typography
                    variant="subtitle2"
                    align="center"
                    sx={{
                      fontSize: "11px",
                      color: secondaryColors.dustyLavender,
                      margin: "auto",
                    }}
                  >
                    Function
                  </Typography>
                )}

                <Typography
                  variant="body1"
                  align="center"
                  sx={{ fontWeight: 700, margin: "auto" }}
                  color="primary.white"
                >
                  {functionInput ? functionInput : "Select a function"}
                </Typography>
              </div>
            </AccordionSummary>
            <AccordionDetails>
              <ButtonInputSelector
                value={functionInput}
                setValue={setFunctionInput}
                type="function"
                industryValue={industry}
                isBlog={true}
                // showAll={showMoreFunctions}
              />
              {/* {!showMoreFunctions && (
              <div style={{ display: "flex", justifyContent: "center" }}>
                <Button
                  variant="text"
                  sx={{
                    color: "white",
                    fontSize: "11px",
                    textDecoration: "underline",
                    mt: 1,
                  }}
                  onClick={() => {
                    setShowMoreFunctions(!showMoreFunctions);
                  }}
                >
                  See more functions
                </Button>
              </div>
            )} */}
            </AccordionDetails>
          </Accordion>
        )}
        {/* <Divider sx={{ mt: 3, mb: 3, borderBottomWidth: 5 }}>
        <Chip
          label="Great! Here's your list of KPIs"
          sx={{ background: "#DCF9DA" }}
        />
      </Divider> */}
        {functionInput && industry && (
          <KPIList
            functionInput={functionInput}
            industry={industry}
            isBlog={true}
          />
        )}
      </HeaderTitle>
    </div>
  );
};

export default BlogKPIGenerator;
