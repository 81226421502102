import { differenceInCalendarMonths } from "date-fns";
import ChevronLeft from "@mui/icons-material/ChevronLeft";
import ChevronRight from "@mui/icons-material/ChevronRight";
import { Grid, IconButton } from "@mui/material";

const NavbarForm = ({
  month,
  onPreviousClick,
  onNextClick,
  disableFrom,
  disableTo,
}) => {
  const styleLeft = {
    // float: "left",
    // cursor: "pointer",
    display: differenceInCalendarMonths(month, disableFrom) === 1 && "none",
  };
  const styleRight = {
    // float: "right",
    // cursor: "pointer",
    display: differenceInCalendarMonths(month, disableTo) === 0 && "none",
  };
  return (
    <Grid container justifyContent="space-between" sx={{ mb: -4 }}>
      <IconButton onClick={() => onPreviousClick()}>
        <ChevronLeft style={styleLeft} />
      </IconButton>
      <IconButton onClick={() => onNextClick()}>
        <ChevronRight style={styleRight} />
      </IconButton>
    </Grid>
  );
};

export default NavbarForm;
