import { format } from "date-fns";

export const formatDate = ({ date, withTime = false, firebaseDate = true }) => {
  // const date = new Date(date);
  let validDate = date;
  if (firebaseDate) {
    validDate = date.toDate();
  } else {
    validDate = new Date(date);
  }
  const dateformat = "d MMM, yy";
  const timeFormat = " h:m bbb";
  return format(validDate, withTime ? dateformat + timeFormat : dateformat);
};
