import { Button, Stack } from "@mui/material";
// import { styled } from "@mui/system";
import InfoRoundedIcon from "@mui/icons-material/InfoRounded";
import { primaryColors } from "@Common/tokens/colors";

const styles = {
  padding: 0,
  minWidth: 0,
  minHeight: 0,
  width: "52px",
  height: "18px",
  fontSize: 9,
  border: `1px solid ${primaryColors.eggplant}`,
  fontWeight: 600,
};

const InfoButton = ({ children, ...props }) => {
  return (
    <Button {...props} sx={styles}>
      <Stack direction="row" alignItems="center">
        <InfoRoundedIcon
          style={{ width: "11px", height: "11px", marginRight: "4px" }}
        />
        {children}
      </Stack>
    </Button>
  );
};

InfoButton.defaultProps = {
  variant: "outlined",
  color: "eggplant",
};

export default InfoButton;
