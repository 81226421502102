import { primaryColors } from "@Common/tokens/colors";
import { Typography } from "@mui/material";
import React from "react";

const TipsFocusAreaContent = () => {
  return (
    <div>
      <Typography variant="h3" sx={{ mb: 2, fontSize: 18 }}>
        What is a focus area?
      </Typography>
      <Typography variant="subtitle1" sx={{ mb: 2 }}>
        Focus Areas are your strategy’s foundation.
      </Typography>
      <Typography variant="subtitle1" sx={{ mb: 5 }}>
        They don’t have to be mutually exclusive, but they should collectively
        get you closer to your vision. Every single one of your company’s
        objectives, projects and KPIs should fall into one or more Focus Area.
      </Typography>
      <Typography variant="h3" sx={{ mb: 2, fontSize: 18 }}>
        Great Focus Areas:
      </Typography>
      <ul
        style={{
          fontSize: 14,
          fontWeight: 500,
          color: primaryColors.eggplant,
        }}
      >
        <li>Inform decisions</li>
        <li>Are specific & clear</li>
        <li>Are short & memorable</li>
        <li>Are collectively exhaustive</li>
        <li>Are more than 3 and less than 6</li>
      </ul>
      <Typography variant="subtitle1" sx={{ mb: 5 }}>
        Refrain from using jargon, numbers or fancy words. Simplicity & clarity
        are powerful allies.
      </Typography>
    </div>
  );
};

export default TipsFocusAreaContent;
