import * as Yup from "yup";

const arrayOfObjects = (val) => {
  return Yup.array(Yup.object(val)).min(1);
};

const verb = Yup.string().required("verb cannot be empty");
const title = Yup.string().required("detail cannot be empty");
const startDate = Yup.date()
  .required("start date required")
  .typeError("start date required");
const endDate = Yup.date()
  .required("end date required")
  .typeError("end date required");
const unit = Yup.string().required("unit required");
const metric = Yup.number()
  .required("metric required")
  .typeError("metric required");

const focusAreaFields = {
  title: Yup.string().required("focus area cannot be empty"),
};

const objectiveFields = {
  title,
  verb,
  startDate,
  endDate,
};

const projectFields = {
  title,
  verb,
  startDate,
  endDate,
};

const kpiFields = {
  title,
  verb,
  metric,
  unit,
  startDate,
  endDate,
};

export const valuesValidation = Yup.object({
  companyName: Yup.string().required("company name cannot be empty"),
  values: arrayOfObjects({
    title: Yup.string().required("value cannot be empty"),
  }).min(1),
});

export const detailsValidation = Yup.object({
  companyName: Yup.string().required("company name cannot be empty"),
});
export const visionValidation = Yup.object({
  companyName: Yup.string().required("company name cannot be empty"),
  vision: Yup.string().required("vision cannot be empty"),
});

const validationHelper = (validateFields) => {
  const validateFocusArea = validateFields.includes("focusAreas");
  const validateObjectvies = validateFields.includes("objectives");
  const validateProjects = validateFields.includes("projects");
  const validateKpis = validateFields.includes("kpis");
  return Yup.object({
    companyName: Yup.string().required("company name cannot be empty"),
    focusAreas:
      validateFocusArea &&
      arrayOfObjects({
        ...focusAreaFields,
        objectives:
          validateObjectvies &&
          arrayOfObjects({
            ...objectiveFields,
            projects:
              validateProjects &&
              arrayOfObjects({
                ...projectFields,
              }),
            kpis:
              validateKpis &&
              arrayOfObjects({
                ...kpiFields,
              }),
          }),
      }),
  });
};

export const focusAreaValidation = validationHelper(["focusAreas"]);

export const objectiveValidation = validationHelper([
  "focusAreas",
  "objectives",
]);

export const projectValidation = validationHelper([
  "focusAreas",
  "objectives",
  "projects",
]);

export const kpiValidation = validationHelper([
  "focusAreas",
  "objectives",
  "kpis",
]);

export const validateAll = Yup.object({
  companyName: Yup.string().required(),
  vision: Yup.string().required(),
  values: arrayOfObjects({
    title: Yup.string().required("value cannot be empty"),
  }).min(1),
  focusAreas: arrayOfObjects({
    ...focusAreaFields,
    objectives: arrayOfObjects({
      ...objectiveFields,
      projects: arrayOfObjects({
        ...projectFields,
      }),
      kpis: arrayOfObjects({
        ...kpiFields,
      }),
    }),
  }),
});
