import MuiAccordion from "@mui/material/Accordion";
import MuiAccordionSummary from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import { styled } from "@mui/material";

export const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  //   border: `1px solid ${theme.palette.divider}`,
  //   pointerEvents: "none",

  background: "transparent",
  "&:not(:last-child)": {
    borderBottom: 0,
  },
  "&:before": {
    display: "none",
  },
}));

export const AccordionSummary = styled((props) => (
  <MuiAccordionSummary {...props} />
))(({ theme }) => ({
  //   background: "transparent",
  minHeight: "unset",
  //   "&.MuiAccordion-root": {
  cursor: "default",
  padding: 0,
  ":hover:not(.Mui-disabled)": {
    cursor: "default",
  },
  "&.Mui-focusVisible": {
    backgroundColor: "inherit",
  },
  //   },
  "&.Mui-focused": {
    backgroundColor: "inherit",
  },
  flexDirection: "row-reverse",
  "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
    transform: "rotate(90deg)",
  },
  "& .MuiAccordionSummary-content": {
    margin: 0,
    //   marginLeft: theme.spacing(1),
    cursor: "default",
  },
}));

export const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  //   padding: theme.spacing(2),
  //   borderTop: "1px solid rgba(0, 0, 0, .125)",
  padding: 0,
  margin: 0,
}));
