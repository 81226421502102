import { Menu, MenuItem, Typography } from "@mui/material";
import React from "react";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";

const FormEditMenu = ({
  anchorEl,
  handleAddAbove,
  handleAddBelow,
  handleClose,
  handleDelete,
  handleEdit,
  showIcons = true,
  showDelete = true,
}) => {
  return (
    <Menu
      aria-labelledby="FormEditMenu"
      anchorEl={anchorEl}
      open={Boolean(anchorEl)}
      onClose={handleClose}
      elevation={0}
      MenuListProps={{
        "aria-labelledby": "FormEditMenuItems",
      }}
    >
      {handleAddAbove && (
        <MenuItem
          onClick={() => {
            handleAddAbove();
            handleClose();
          }}
        >
          {showIcons && (
            <ArrowUpwardIcon
              sx={{
                fontSize: "15px",
                stroke: "black",
                strokeWidth: 0.5,
                mr: 1,
              }}
            />
          )}
          <Typography variant="subtitle1">Add above</Typography>
        </MenuItem>
      )}

      {handleAddBelow && (
        <MenuItem
          onClick={() => {
            handleAddBelow();
            handleClose();
          }}
        >
          {showIcons && (
            <ArrowDownwardIcon
              sx={{
                fontSize: "15px",
                stroke: "black",
                strokeWidth: 0.5,
                mr: 1,
              }}
            />
          )}
          <Typography variant="subtitle1">Add below</Typography>
        </MenuItem>
      )}

      {handleDelete && showDelete && (
        <MenuItem
          onClick={() => {
            handleDelete();
            handleClose();
          }}
        >
          {showIcons && <DeleteForeverIcon sx={{ fontSize: "15px", mr: 1 }} />}
          {/* <DeleteIcon /> */}
          <Typography variant="subtitle1">Delete</Typography>
        </MenuItem>
      )}

      {handleEdit && (
        <MenuItem
          onClick={() => {
            handleEdit();
            handleClose();
          }}
        >
          <Typography variant="subtitle1">Edit</Typography>
        </MenuItem>
      )}
    </Menu>
  );
};

export default FormEditMenu;
