import { primaryColors } from "@Common/tokens/colors";
import { Typography } from "@mui/material";
import React from "react";

const TipsVisionContent = () => {
  return (
    <div>
      <Typography variant="h3" sx={{ mb: 2, fontSize: 18 }}>
        What is a Vision?
      </Typography>
      <Typography
        variant="subtitle1"
        color={primaryColors.eggplant}
        sx={{ mb: 5 }}
      >
        What we call vision, others call mission. It’s the (impossible) future
        state of the world your company strives for. The destination that
        ultimately guides every single decision. You can see it with your mind’s
        eye, that’s why it’s called a “vision.”
      </Typography>
      <Typography variant="h3" sx={{ mb: 2, fontSize: 18 }}>
        A great vision statement is:
      </Typography>
      <ul
        style={{
          fontSize: 14,
          fontWeight: 500,
          color: primaryColors.eggplant,
        }}
      >
        <li>
          <Typography sx={{ fontWeight: 700 }}>Ambitious</Typography>
          Impossible and inspiring like a big dream.
        </li>
        <li>
          <Typography sx={{ fontWeight: 700 }}>Affirmative</Typography>
          You want your company to stand “for” something, not “against”
          anything, e.g. “to make traveling safe” not “eradicate dangerous
          journeys.”
        </li>
        <li>
          <Typography sx={{ fontWeight: 700 }}>Durable</Typography>
          To change and disruptions. Specific enough to identify your industry
          (maybe your organization, too), but broad enough as to not limit your
          options of advancing towards it.
        </li>
        <li>
          <Typography sx={{ fontWeight: 700 }}>Beneficial & Open</Typography>
          To everyone involved. A rallying cry that invites everyone who
          believes in it to contribute and for the primary benefit of the
          receivers.
        </li>
      </ul>
      <Typography variant="subtitle1" sx={{ mb: 5 }}>
        Vision statements typically remain the same, that’s why they are
        unattainable. They rarely outline services and products, but they spark
        human imagination. They paint a clear picture of the ambitious future
        state with simple language, i.e. no jargon.
      </Typography>
    </div>
  );
};

export default TipsVisionContent;
