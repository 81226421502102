import { Typography } from "@mui/material";
import React from "react";

const variantToColor = {
  project: "#F9DBDA",
  objective: "#DAEDF9",
  focusArea: "#ECE8F9",
  kpi: "#DCF9DA",
  complete: "#CBFFC7",
  draft: "#EEEEEE",
};

const HeaderTag = ({ children, variant, sx }) => {
  return (
    <Typography
      variant="subtitle2"
      sx={{
        bgcolor: variantToColor[variant],
        p: "5px 11px",
        lineHeight: "13px",
        // height: "24px",
        width: "fit-content",
        borderRadius: "4px",
        ...sx,
      }}
    >
      {children}
    </Typography>
  );
};

export default HeaderTag;
