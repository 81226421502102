import {
  Box,
  Container,
  Divider,
  IconButton,
  Stack,
  Typography,
} from "@mui/material";
import { Helmet } from "react-helmet-async";
import { validateAll } from "@Strategy-planning/data/validations";
import FormContainer from "@Strategy-planning/layouts/FormContainer";
import React from "react";
import RowTemplate from "./components/RowTemplate";
import PlanHeader from "./PlanHeader";
import VisionValuesHeader from "./VisionValuesHeader";

import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import FocusAreaBody from "./FocusAreaBody";
import SubmitButton from "@Strategy-planning/layouts/StepperLayout/components/SubmitButton";
import { useLocation } from "react-router-dom";
import { storeLastLocation } from "@Strategy-planning/storage/lastLocation";

const TableHeader = ({ showDate, setShowDate }) => {
  return (
    <RowTemplate
      hasEdit={false}
      metric={
        <Typography sx={{ fontSize: "14px", fontWeight: 600 }}>
          Metric
        </Typography>
      }
      unit={
        <Typography sx={{ fontSize: "14px", fontWeight: 600 }}>Unit</Typography>
      }
      date={
        <>
          <Stack direction="row" sx={{ mr: "11px" }} alignItems="center">
            <Typography sx={{ fontSize: "14px", fontWeight: 600, flexGrow: 1 }}>
              Date
            </Typography>
            <IconButton onClick={() => setShowDate(!showDate)}>
              {showDate ? <VisibilityIcon /> : <VisibilityOffIcon />}
            </IconButton>
          </Stack>
        </>
      }
      gridStyles={{ margin: "10px 0px" }}
    />
  );
};

const Plan = ({ initialValues }) => {
  const { pathname } = useLocation();

  React.useEffect(() => {
    storeLastLocation(pathname);
  }, [pathname]);
  const [showDate, setShowDate] = React.useState(true);
  return (
    <>
      <Helmet>
        <meta
          name="description"
          content="Plan page for strategy planning tool"
        />
      </Helmet>
      <FormContainer
        initialValues={initialValues}
        validationSchema={validateAll}
      >
        <PlanHeader />
        <Container maxWidth="lg" sx={{ mt: "-30px" }}>
          <VisionValuesHeader />
          <Box
            sx={{
              bgcolor: "white",
              p: "5px 0px",
              mb: 2,
              mt: 3,
              display: "grid",
              maxWidth: 1200,
              overflow: "scroll",
            }}
          >
            <TableHeader setShowDate={setShowDate} showDate={showDate} />
            <Divider />
            <FocusAreaBody showDate={showDate} />
          </Box>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              marginTop: "30px",
              marginBottom: "40px",
            }}
          >
            <SubmitButton label="Save plan" />
          </div>
        </Container>
      </FormContainer>
    </>
  );
};

export default Plan;
