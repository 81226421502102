import { db } from "@Common/firebase";
import { getUserToken } from "@Common/storage/authTokenStorage";
import {
  detailsValidation,
  focusAreaValidation,
  kpiValidation,
  objectiveValidation,
  projectValidation,
  validateAll,
  valuesValidation,
  visionValidation,
} from "@Strategy-planning/data/validations";
import { SPT_FIELD_NAME } from "@Strategy-planning/tokens/fieldNameToken";
import { SPT_PATH_ORDER } from "@Strategy-planning/tokens/strategyPlanningPaths";
import { doc, setDoc, updateDoc } from "firebase/firestore";
import { cloneDeep } from "lodash";

const getIsPlanCreated = () => {
  return localStorage.getItem("isUser") === "true";
};

const setIsPlanCreated = (val) => {
  return localStorage.setItem("isUser", val);
};

export const planOnSubmit = async (data, planId, nextPage, gaNextPageValue) => {
  const visited = [
    await detailsValidation.isValid(data),
    await visionValidation.isValid(data),
    await valuesValidation.isValid(data),
    await focusAreaValidation.isValid(data),
    await objectiveValidation.isValid(data),
    await projectValidation.isValid(data),
    await kpiValidation.isValid(data),
    await validateAll.isValid(data),
  ];

  const lastVisited = visited.lastIndexOf(true);
  const nextPagePath = nextPage?.split("/").pop() || "plan";
  const lastVisitedPath = SPT_PATH_ORDER[lastVisited + 1]?.route || "plan";
  if (lastVisitedPath !== "plan" && lastVisitedPath === nextPagePath) {
    window.gtag("event", "user_engagement_strategy_plan", {
      event_category: "completed_step",
      event_label: gaNextPageValue(),
    });
  }
  if (lastVisitedPath !== "plan") {
    setIsPlanCreated(false);
  }
  console.log(
    getIsPlanCreated(),
    lastVisitedPath === "plan" && lastVisitedPath === nextPagePath
  );
  if (lastVisitedPath === "plan" && lastVisitedPath === nextPagePath) {
    if (!getIsPlanCreated()) {
      setIsPlanCreated(true);
      window.gtag("event", "user_engagement_strategy_plan", {
        event_category: "final_plan_accessed",
        event_label: gaNextPageValue(),
      });
    }
  }
  try {
    if (getUserToken()) {
      const complete = await validateAll.isValid(data);
      const userPlansRef = doc(db, "userPlans", getUserToken());
      await updateDoc(userPlansRef, {
        [planId]: {
          lastChanged: new Date(),
          title: data[SPT_FIELD_NAME.COMPANY_NAME],
          complete,
          lastVisited: SPT_PATH_ORDER[lastVisited + 1]?.route || "plan",
        },
      });
    }

    const userDataRef = doc(db, "plan", planId);
    const temp = cloneDeep(data);
    temp?.focusAreas?.forEach((focusArea) => {
      focusArea.objectives.forEach((objective) => {
        objective.startDate = objective?.startDate?.getTime() || null;
        objective.endDate = objective?.endDate?.getTime() || null;
        objective.projects.forEach((project) => {
          project.startDate = project?.startDate?.getTime() || null;
          project.endDate = project?.endDate?.getTime() || null;
        });
        objective.kpis.forEach((kpi) => {
          kpi.startDate = kpi?.startDate?.getTime() || null;
          kpi.endDate = kpi?.endDate?.getTime() || null;
        });
      });
    });
    await setDoc(userDataRef, { ...temp, visited });
    return true;
  } catch (err) {
    console.log(err);
    return false;
  }
};
