import { Stack } from "@mui/material";
import React from "react";
import EditButton from "./EditButton";
import HeaderTag from "./HeaderTag";

const HeaderTitle = ({ children, variant, ...otherProps }) => {
  return (
    <>
      <Stack direction="row" spacing={1} alignItems="center">
        <HeaderTag variant={variant}>{children}</HeaderTag>
        <EditButton {...otherProps} />
      </Stack>
    </>
  );
};

export default HeaderTitle;
