import {
  Alert,
  Avatar,
  Box,
  Button,
  Container,
  Grid,
  Link,
  Typography,
} from "@mui/material";
import React from "react";
import { Helmet } from "react-helmet-async";

import {
  signInWithEmailAndPassword,
  createUserWithEmailAndPassword,
} from "firebase/auth";
import "./login.css";

import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import * as Yup from "yup";
import { useLocation, useNavigate, Link as RouterLink } from "react-router-dom";

import { doc, getDoc, setDoc } from "firebase/firestore";

import { useSnackbar } from "notistack";
import { auth, db, signInWithGoogle } from "@Common/firebase";
import { storeUserToken } from "@Common/storage/authTokenStorage";
import {
  getAnonymousToken,
  storeAnonymousToken,
} from "@Common/storage/anonymousTokenStorage";
import { getLastLocation } from "@Strategy-planning/storage/lastLocation";
import {
  detailsValidation,
  focusAreaValidation,
  kpiValidation,
  objectiveValidation,
  projectValidation,
  validateAll,
  valuesValidation,
  visionValidation,
} from "@Strategy-planning/data/validations";
import LoadingElement from "@Common/components/LoadingElement";
import FormTextInput from "@Common/components/react-hook-form-ui/FormTextInput";
import { FormProvider, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { SPT_PATH_ORDER } from "@Strategy-planning/tokens/strategyPlanningPaths";
import { PATH_STRATEGY_PLANNING } from "@Common/tokens/routeTokens";

const sleep = (time) => new Promise((acc) => setTimeout(acc, time));

const Login = () => {
  React.useEffect(() => {
    document.title = "Login - strategy planning tool";
  }, []);
  const { enqueueSnackbar } = useSnackbar();
  const { state } = useLocation();
  console.log(state);
  const [error, setError] = React.useState(state?.error || "");
  const email = state?.email || "";
  // const passwordError = state?.error || false;
  const password = state?.password || "";
  // const hasError = Boolean(error?.password) || passwordError;

  const validate = Yup.object({
    email: Yup.string().email("Email is invalid").required("Email is required"),
    password: Yup.string()
      .min(6, "Password must be at least 6 charaters")
      .required("Password is required"),
  });
  const [submitting, setSubmitting] = React.useState(false);

  const navigate = useNavigate();

  const handleLogin = async (email, password) => {
    setSubmitting(true);
    setError(false);
    try {
      const signInResult = await signInWithEmailAndPassword(
        auth,
        email,
        password
      );
      enqueueSnackbar("Login Success!", { variant: "success" });
      await handleDataForLogin(signInResult);
      storeUserToken(signInResult.user.uid);
      storeAnonymousToken(null);
      setSubmitting(false);
      navigate(getLastLocation());
    } catch (error) {
      console.log("email and password login error", error);
      const errorCode = error.code;
      if (errorCode === "auth/user-not-found") {
        enqueueSnackbar("User doesn't exist!", { variant: "error" });
        sleep(500);
        enqueueSnackbar("Creating new user!", { variant: "info" });
        try {
          const signInResult = await createUserWithEmailAndPassword(
            auth,
            email,
            password
          );
          await handleDataForLogin(signInResult);
          storeUserToken(signInResult.user.uid);
          storeAnonymousToken(null);
          setSubmitting(false);
          navigate(getLastLocation());
        } catch (error) {
          setError(error.code.split("/").pop());
          enqueueSnackbar(error.code, { variant: "error" });
          setSubmitting(false);
        }
      }
      if (errorCode === "auth/wrong-password") {
        enqueueSnackbar("Password is incorrect!", { variant: "error" });
        setError("Password is incorrect");
      } else {
        console.log(error.code);
        enqueueSnackbar(error.code, { variant: "error" });
      }
      setSubmitting(false);
    }
    setSubmitting(false);
  };

  const handleGoogle = async () => {
    setSubmitting(true);
    setError(false);
    try {
      const signInResult = await signInWithGoogle();

      enqueueSnackbar("Login Success!", { variant: "success" });
      await handleDataForLogin(signInResult);
      storeUserToken(signInResult.user.uid);
      storeAnonymousToken(null);
      setSubmitting(false);
      navigate(getLastLocation());
    } catch (error) {
      console.log("google singin error", error);
      setSubmitting(false);
    }
    setSubmitting(false);
  };

  const handleDataForLogin = async (signInResult) => {
    const anonymousToken = getAnonymousToken();
    if (anonymousToken) {
      const planSnap = await getDoc(doc(db, "plan", anonymousToken));
      const planData = planSnap.data();
      planData?.focusAreas?.forEach((focusArea) => {
        focusArea.objectives.forEach((objective) => {
          objective.startDate = objective?.startDate
            ? new Date(objective?.startDate)
            : null;
          objective.endDate = objective?.endDate
            ? new Date(objective?.endDate)
            : null;
          objective.projects.forEach((project) => {
            project.startDate = project?.startDate
              ? new Date(project?.startDate)
              : null;
            project.endDate = project?.endDate
              ? new Date(project?.endDate)
              : null;
          });
          objective.kpis.forEach((kpi) => {
            kpi.startDate = kpi?.startDate ? new Date(kpi?.startDate) : null;
            kpi.endDate = kpi?.endDate ? new Date(kpi?.endDate) : null;
          });
        });
      });
      const visited = [
        await detailsValidation.isValid(planData),
        await visionValidation.isValid(planData),
        await valuesValidation.isValid(planData),
        await focusAreaValidation.isValid(planData),
        await objectiveValidation.isValid(planData),
        await projectValidation.isValid(planData),
        await kpiValidation.isValid(planData),
        await validateAll.isValid(planData),
      ];
      const lastVisited = visited.lastIndexOf(true);
      console.log(lastVisited, visited);
      await setDoc(
        doc(db, "userPlans", signInResult.user.uid),
        {
          [anonymousToken]: {
            title: planData.companyName,
            lastChanged: new Date(),
            lastVisited: SPT_PATH_ORDER[lastVisited + 1]?.route || "plan",
            complete: visited.pop(),
          },
        },
        { merge: true }
      );
      enqueueSnackbar("Plan added to dashboard", { variant: "success" });
      return;
    }
  };

  const methods = useForm({
    resolver: yupResolver(validate),
    defaultValues: { email, password },
  });

  if (submitting) return <LoadingElement>Logging in</LoadingElement>;

  return (
    <>
      <Helmet>
        {/* <title>Login - strategy planning tool</title> */}
        <meta
          name="description"
          content="Login page for strategy planning tool"
        />
      </Helmet>

      <FormProvider {...methods}>
        <form
          onSubmit={methods.handleSubmit((data) =>
            handleLogin(data.email, data.password)
          )}
        >
          <Container maxWidth="xs">
            <Avatar
              sx={{
                m: "auto",
                bgcolor: "secondary.main",
                mt: 15,
                mb: 1,
              }}
            >
              <AccountCircleIcon />
            </Avatar>
            <Typography
              component="h1"
              variant="h5"
              textAlign="center"
              sx={{ mb: 3 }}
            >
              Sign in
            </Typography>
            <Box sx={{ mt: 1 }} />
            {error && <Alert severity="error">{error}</Alert>}
            <FormTextInput
              margin="normal"
              id="email"
              placeholder="Email Address"
              name="email"
              fullWidth
            />
            <FormTextInput
              margin="normal"
              fullWidth
              name="password"
              placeholder="Password"
              type="password"
              id="password"
            />
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
            >
              Sign in
            </Button>
            {/* {error && (
              <Typography color="red" sx={{ ml: 1, mb: 2 }}>
                {error}
              </Typography>
            )} */}

            <button className="login-with-google-btn" onClick={handleGoogle}>
              Sign in with Google
            </button>
            <Grid container>
              <Grid item xs>
                {/* <Link href="#" variant="body2">
                        Forgot password?
                      </Link> */}
              </Grid>
              <Grid item>
                <Link
                  component={RouterLink}
                  to={`/${PATH_STRATEGY_PLANNING}/signUp`}
                  variant="body2"
                >
                  {"Don't have an account? Sign Up"}
                </Link>
              </Grid>
            </Grid>
          </Container>
        </form>
      </FormProvider>
    </>
  );
};

export default Login;
