import FormFreesoloSelect from "@Common/components/react-hook-form-ui/FormFreesoloSelect";
import FormTextInput from "@Common/components/react-hook-form-ui/FormTextInput";
import { primaryColors } from "@Common/tokens/colors";
import { Divider, Typography, alpha, Grid } from "@mui/material";
import HeaderTag from "@Strategy-planning/components/HeaderTag";

const ProjectKPITitle = ({ type, label, name }) => {
  return (
    <>
      <Grid container direction="row" sx={{ pl: 6 }} alignItems="center">
        <Divider
          orientation="vertical"
          sx={{
            borderRightWidth: 3,
            borderRadius: 1,
            height: "18px",
            mr: 1,
          }}
        />
        <Grid item sx={{ width: "80px" }}>
          <HeaderTag variant={type} sx={{ margin: "2px 0px" }}>
            {label}
          </HeaderTag>
        </Grid>
        <Grid item sx={{ maxWidth: "120px" }}>
          <FormFreesoloSelect
            name={`${name}.verb`}
            textfieldProps={{
              variant: "standard",
              autosize: true,
              placeholder: "Select verb",
              InputProps: {
                sx: {
                  fontSize: "14px",
                  fontWeight: 500,
                  color: alpha(primaryColors.eggplant, 0.87) + " !important",
                },
              },
            }}
            showTooltip={false}
          />
        </Grid>
        <Grid item sx={{ mb: "4px" }}>
          <Typography
            variant="h6"
            sx={{
              fontSize: "14px",
              fontWeight: 500,
              mr: 1,
              color: alpha(primaryColors.eggplant, 0.87) + " !important",
            }}
          >
            :
          </Typography>
        </Grid>
        <Grid item sx={{ flexGrow: 1, width: "200px" }}>
          <FormTextInput
            name={`${name}.title`}
            autosize
            variant="standard"
            placeholder="Project Title"
            inputProps={{
              sx: {
                fontSize: "14px",
                fontWeight: 500,
                color: alpha(primaryColors.eggplant, 0.87) + " !important",
              },
            }}
            showTooltip={false}
          />
        </Grid>
      </Grid>
    </>
  );
};

export default ProjectKPITitle;
