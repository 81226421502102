import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import { ReactComponent as CascadeTools } from "@Common/assets/cascade-tools.svg";
import { Divider, Grid } from "@mui/material";

const Header = ({ leftContent, rightContent, hideToolLogoOnXS = true }) => {
  return (
    <Box sx={{ flexGrow: 1, mb: "150px" }}>
      <AppBar position="fixed" elevation={0}>
        <Toolbar>
          <Grid
            container
            direction="row"
            alignItems="center"
            // alignItems="center"
            sx={{ minHeight: 80 }}
          >
            <Grid
              container
              item
              justifyContent="flex-start"
              sx={{ width: { xs: "30%", md: "40%" } }}
            >
              {leftContent}
            </Grid>
            <Box
              component={Grid}
              sx={{
                display: hideToolLogoOnXS && { xs: "none", md: "block" },
                width: { xs: "100%", md: "20%" },
                textAlign: "center",
              }}
              item
            >
              <CascadeTools style={{ textAlign: "center" }} />
            </Box>
            <Grid
              container
              item
              justifyContent="flex-end"
              sx={{ width: { xs: "70%", md: "40%" } }}
            >
              {rightContent}
            </Grid>
          </Grid>
        </Toolbar>
        <Divider />
      </AppBar>
    </Box>
  );
};

export default Header;
