import theme from "@Common/themes/theme";
import { primaryColors, secondaryColors } from "@Common/tokens/colors";
import { alpha, Button, Grid } from "@mui/material";
import React from "react";
import kpiData from "./kpiData.json";

const defaultStyle = {
  padding: "4px 12px",
  borderColor: "#EAEAEA",
  background: primaryColors.white,
  transition: "transform 0.16s ease-in-out, opacity 0.25s ease-in-out",
  "&:hover": {
    transform: "scale3d(1.07, 1.08, 1)",
    borderColor: primaryColors.purpleRain,
    background: primaryColors.white,
  },
  width: "120px",
  height: "100%",
  fontSize: "14px",
};

const blogStyle = {
  padding: "4px 12px",
  borderColor: secondaryColors.dustyLavender,
  background: "rgba(255, 255, 255, 0.1)",
  color: "white",
  transition: "transform 0.16s ease-in-out, opacity 0.25s ease-in-out",
  "&:hover": {
    transform: "scale3d(1.07, 1.08, 1)",
    // borderColor: primaryColors.purpleRain,
    borderColor: alpha("#d3ccff", 1),
    opacity: 1,
    // background: primaryColors.white,
    background: "rgba(255, 255, 255, 0.1)",
  },
  width: "100%",
  height: "100%",
  fontSize: "12px",
};

const ButtonInputSelector = ({
  value,
  setValue,
  type,
  industryValue,
  isBlog = false,
  showAll,
}) => {
  let data = [];
  if (type === "industry") {
    data = Object.keys(kpiData);
  } else {
    data = Object.keys(kpiData[industryValue]);
  }
  // data.sort(function (a, b) {
  //   return (
  //     a.length - b.length || // sort by length, if equal then
  //     a.localeCompare(b)
  //   ); // sort by dictionary order
  // });
  return (
    <div>
      <Grid
        container
        justifyContent="stretch"
        alignItems="stretch"
        spacing={1}
        sx={{ mt: 1 }}
      >
        {data.map((item, index) => (
          <>
            {(showAll || index < 15) && (
              <Grid
                item
                key={index}
                // xs={6}
                // sm={4}
                sx={{
                  [theme.breakpoints.down("xs")]: {
                    // flex: "0 0 50%",
                    flexGrow: 1,
                  },
                  [theme.breakpoints.up("xs")]: {
                    // flex: "0 0 33.333%",
                    flexGrow: 1,
                  },
                }}
              >
                <Button
                  variant="outlined"
                  color="eggplant"
                  onClick={() => setValue(item)}
                  sx={{
                    ...(isBlog ? blogStyle : defaultStyle),
                    width: "100%",
                    opacity: value && value !== item ? 0.5 : 1,
                  }}
                >
                  {item}
                </Button>
              </Grid>
            )}
          </>
        ))}
      </Grid>
    </div>
  );
};

export default ButtonInputSelector;
