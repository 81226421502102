import {
  alpha,
  Box,
  IconButton,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import React from "react";
import CopyToClipboard from "react-copy-to-clipboard";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import kpiData from "./kpiData.json";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { primaryColors, secondaryColors } from "@Common/tokens/colors";

// const data = [
//   "Project Delivery Time",
//   "Service Level Agreements (SLAs)",
//   "Employee engagement and satisfaction",
// ];

// const dateFormat = (date) => {
//   return add(new Date(), {
//     months: date,
//   }).toLocaleDateString("en-US");
// };

// const formatKPI = (kpi) => {
//   if (kpi.endsWith("by")) {
//     return kpi.slice(0, -3);
//   }
//   return kpi;
// };

const boxStyles = {
  p: "5px 5px 5px 12px",
  border: "2px solid #EAEAEA",
  transition: "transform 0.15s ease-in-out, opacity 0.25s ease-in-out",
  "&:hover": {
    borderColor: alpha("#594BDB", 0.5),
    transform: "scale3d(1.01, 1.01, 1)",
    // boxShadow: `0 0 0 1px ${alpha(primaryColors.purpleRain, 0.5)}`,
  },
  mt: 2,
  borderRadius: 2,
  background: "white",
};

const blogBoxStyles = {
  p: "5px 5px 5px 12px",
  border: "2px solid " + secondaryColors.dustyLavender,
  background: "rgba(255, 255, 255, 0.1)",
  transition: "transform 0.15s ease-in-out, opacity 0.25s ease-in-out",
  "&:hover": {
    borderColor: alpha("#d3ccff", 1),
    transform: "scale3d(1.01, 1.01, 1)",
    // boxShadow: `0 0 0 1px ${alpha(primaryColors.purpleRain, 0.5)}`,
  },
  mt: 2,
  borderRadius: 2,
};

const KPICard = ({ KPIValue, isBlog }) => {
  const [tooltipVal, setTooltipVal] = React.useState("Copy to clipboard");
  return (
    <Box sx={isBlog ? blogBoxStyles : boxStyles}>
      <Stack direction="row" justifyContent="space-between" alignItems="center">
        <div>
          <Typography
            variant="body1"
            sx={{ color: isBlog && "white", fontSize: "14px" }}
          >
            {KPIValue}
          </Typography>
          {/* <Typography variant="h5" sx={{ color: "purple" }}>
            {" before " + dateFormat(KPIValue.date)}
          </Typography> */}
        </div>
        <CopyToClipboard
          text={KPIValue}
          onCopy={() => {
            setTooltipVal("Copied!!");
            setTimeout(() => {
              setTooltipVal("Copy to clipboard");
            }, 2000);
          }}
        >
          <Tooltip title={tooltipVal}>
            <IconButton
              disableRipple={false}
              disableTouchRipple={true}
              sx={{ borderRadius: 1, color: isBlog && "white" }}
            >
              <ContentCopyIcon />
            </IconButton>
          </Tooltip>
        </CopyToClipboard>
      </Stack>
    </Box>
  );
};

const KPIList = ({ functionInput, industry, isBlog = false }) => {
  console.log(functionInput, industry);
  return (
    <div>
      <Typography
        variant="body1"
        align={isBlog ? "center" : "left"}
        sx={{
          mt: 2,
          fontWeight: 700,
          color: isBlog ? primaryColors.white : primaryColors.eggplant,
        }}
      >
        Great! Here's your list of KPIs
      </Typography>
      {kpiData[industry][functionInput].map((val, index) => (
        <KPICard KPIValue={val} key={index} isBlog={isBlog} />
      ))}
      <Box
        sx={{
          ...boxStyles,
          backgroundColor: "#1D1652",
          borderColor: secondaryColors.dustyLavender,
          "&:hover": {
            transform: "scale3d(1.01, 1.01, 1)",
            // boxShadow: `0 0 0 1px ${alpha(primaryColors.purpleRain, 0.5)}`,
          },
        }}
      >
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Typography variant="body1" color="white">
            Download our free KPI Cheat Sheet
          </Typography>
          {/* <Tooltip title={tooltipVal}> */}
          <IconButton
            disableRipple={false}
            disableTouchRipple={true}
            onClick={() =>
              window.open("https://www.cascade.app/kpi-cheat-sheets", "_blank")
            }
            sx={{
              borderRadius: 1,
              color: "white",
              // background: "#EAEAEA",
              "&:hover": {
                backgroundColor: "#6e698fb0",
              },
            }}
          >
            <ArrowForwardIcon />
          </IconButton>
          {/* </Tooltip> */}
        </Stack>
      </Box>
    </div>
  );
};

export default KPIList;
