export const SPT_FIELD_NAME = {
  COMPANY_NAME: "companyName",
  VISION: "vision",
  VALUE: "values",
  FOCUS_AREA: "focusAreas",
  OBJECTIVE: "objectives",
  PROJECT: "projects",
  KPI: "kpis",
  VISITED: "visited",
};
