import React from "react";
import { Controller, useFormContext } from "react-hook-form";
import TextInput from "../TextInput";
import { get } from "lodash";
import ErrorToolTip from "../ErrorToolTip";

const FormTextInput = ({ name, showTooltip = true, ...otherProps }) => {
  const {
    control,
    formState: { errors },
  } = useFormContext();
  const error = get(errors, name)?.message;
  return (
    <>
      <Controller
        render={({ field }) => (
          <ErrorToolTip error={error} showTooltip={showTooltip}>
            <TextInput {...field} error={!!error} {...otherProps} />
          </ErrorToolTip>
        )}
        name={name}
        control={control}
      />
    </>
  );
};

export default FormTextInput;
