export const defaultCompanyName = "";

export const defaultVision = "";

export const defaultValues = { title: "" };

export const defaultProject = {
  verb: "",
  title: "",
  startDate: null,
  endDate: null,
};

export const defaultKpi = {
  verb: "",
  title: "",
  startDate: null,
  endDate: null,
  metric: "",
  unit: "",
};

export const defaultObjective = {
  verb: "",
  title: "",
  startDate: null,
  endDate: null,
  projects: [defaultProject],
  kpis: [defaultKpi],
};

export const defaultFocusArea = {
  title: "",
  objectives: [defaultObjective],
};

export const defaultInitialValues = {
  companyName: defaultCompanyName,
  vision: defaultVision,
  values: [defaultValues],
  focusAreas: [defaultFocusArea],
  visited: [false, false, false, false, false, false, false, false],
};
