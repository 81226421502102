import { Container } from "@mui/material";
import { storeLastLocation } from "@Strategy-planning/storage/lastLocation";
import { SPT_PATH_ORDER } from "@Strategy-planning/tokens/strategyPlanningPaths";
import React from "react";
import { useLocation } from "react-router-dom";
import PlanDetails from "./components/PlanDetails";
import StepperFooter from "./components/StepperFooter";
import StepperHeader from "./components/StepperHeader";
import SubmitButton from "./components/SubmitButton";

const StepperLayout = ({
  headerData,
  detailsData,
  maxWidth,
  children,
  submitButtonJustify = "center",
}) => {
  const { pathname } = useLocation();

  React.useEffect(() => {
    storeLastLocation(pathname);
  }, [pathname]);

  const index = SPT_PATH_ORDER.findIndex(
    (obj) => obj.fullPath.split("/").pop() === pathname.split("/").pop()
  );
  const nextPage = SPT_PATH_ORDER[index + 1]?.fullPath || "plan";
  return (
    <>
      <StepperHeader {...headerData} />
      <Container maxWidth={maxWidth}>
        <PlanDetails {...detailsData} />
        <div style={{ height: "20px" }} />
        {children}
        <div style={{ textAlign: submitButtonJustify, marginTop: "40px" }}>
          <SubmitButton nextPage={nextPage} />
        </div>
        <StepperFooter />
      </Container>
    </>
  );
};

export default StepperLayout;
