import { Typography } from "@mui/material";
import React from "react";

const TipsKPIContent = () => {
  return (
    <div>
      <Typography variant="h3" sx={{ mb: 2, fontSize: 18 }}>
        What is a KPI?
      </Typography>
      <Typography variant="subtitle1" sx={{ mb: 5 }}>
        The final part of your strategy is KPIs (Key Performance Indicators),
        the tools that track your progress.
      </Typography>
      <Typography variant="h3" sx={{ mb: 2, fontSize: 18 }}>
        Lagging KPIs
      </Typography>
      <Typography variant="subtitle1" sx={{ mb: 5 }}>
        Indicators of past performance. Every objective has one. It’s how you
        know you’ve completed your Goal. It’s your success measure.
      </Typography>
      <Typography variant="h3" sx={{ mb: 2, fontSize: 18 }}>
        Leading KPIs
      </Typography>
      <Typography variant="subtitle1" sx={{ mb: 5 }}>
        Action-oriented indicators that you can
        <strong> directly influence </strong>and can predict whether you’ll hit
        your lagging KPIs. They usually align with Projects.
      </Typography>

      <Typography variant="h3" sx={{ mb: 2, fontSize: 18 }}>
        How to write a KPI
      </Typography>
      <Typography variant="subtitle1" sx={{ mb: 5 }}>
        <span style={{ color: "#FFAD31" }}>Action (Verb)</span> +
        <span style={{ color: "#218CB5" }}> Detail</span> +
        <span style={{ color: "#9885B6" }}> Value</span> +
        <span style={{ color: "#EE6A59" }}> Unit</span> +
        <span style={{ color: "#3C9D9D" }}> Deadline</span>
        <br />
        Ex. <span style={{ color: "#FFAD31" }}>Increase</span>
        <span style={{ color: "#218CB5" }}> new HubSpot lead profiles</span>
        <span style={{ color: "#9885B6" }}> to 40,000</span>
        <span style={{ color: "#EE6A59" }}> people</span>
        <span style={{ color: "#3C9D9D" }}> by 31st December 2019.</span>
      </Typography>
    </div>
  );
};

export default TipsKPIContent;
