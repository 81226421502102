import FormTextInput from "@Common/components/react-hook-form-ui/FormTextInput";
import { Grid, IconButton } from "@mui/material";
import { focusAreaValidation } from "@Strategy-planning/data/validations";
import FormContainer from "@Strategy-planning/layouts/FormContainer";
import StepperLayout from "@Strategy-planning/layouts/StepperLayout/StepperLayout";
import { SPT_FIELD_NAME } from "@Strategy-planning/tokens/fieldNameToken";
import React from "react";
import { useFieldArray, useFormContext } from "react-hook-form";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import AddButton from "@Common/components/AddButton";
import { defaultFocusArea } from "@Strategy-planning/data/defaults";
import TipsFocusAreaContent from "./Plan/tipsLayout/TipsFocusAreaContent";

const formData = {
  validationSchema: focusAreaValidation,
};

const detailsData = {
  title: "What are your focus areas?",
  details:
    "A Focus Area is a high level aim or intention that will help you to realize your Vision. They are calls to action that point your employees in the right direction.",
  endDivider: false,
  tipsTitle: "Focus Area",
  tipsContent: <TipsFocusAreaContent />,
};

const FocusAreaFields = () => {
  const { control } = useFormContext();
  const { fields, append, remove } = useFieldArray({
    control,
    name: SPT_FIELD_NAME.FOCUS_AREA,
  });
  const hasOneField = fields.length === 1;
  return (
    <>
      {fields.map((value, index) => (
        <Grid
          container
          alignItems="center"
          spacing={1}
          sx={{ mt: 1, mb: 1 }}
          key={value.id}
        >
          <Grid item flexGrow={1}>
            <FormTextInput
              name={`${SPT_FIELD_NAME.FOCUS_AREA}[${index}].title`}
              placeholder="Add a focus area"
              fullWidth
              autoFocus
            />
          </Grid>
          <Grid item>
            {!hasOneField && (
              <IconButton onClick={() => remove(index)}>
                <CloseRoundedIcon />
              </IconButton>
            )}
          </Grid>
        </Grid>
      ))}
      <AddButton onClick={() => append(defaultFocusArea)}>
        Add Focus Area
      </AddButton>
    </>
  );
};

const FocusArea = ({ initialValues }) => {
  return (
    <div>
      <FormContainer
        initialValues={initialValues}
        validationSchema={formData.validationSchema}
      >
        <StepperLayout detailsData={detailsData} maxWidth="md">
          <FocusAreaFields />
        </StepperLayout>
      </FormContainer>
    </div>
  );
};

export default FocusArea;
