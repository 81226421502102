import {
  Button,
  Container,
  Divider,
  Stack,
  Typography,
  alpha,
  IconButton,
} from "@mui/material";
import { Box } from "@mui/system";
import React from "react";

import {
  collection,
  doc,
  setDoc,
  addDoc,
  onSnapshot,
  getDoc,
  deleteField,
  updateDoc,
  deleteDoc,
} from "firebase/firestore";

import { useLocation, useNavigate } from "react-router-dom";
import { useSnackbar } from "notistack";
import { auth, db, userSignOut } from "@Common/firebase";
import { storeLastLocation } from "@Strategy-planning/storage/lastLocation";
import { getUserToken } from "@Common/storage/authTokenStorage";
import { defaultInitialValues } from "@Strategy-planning/data/defaults";
import Header from "@Common/components/Header";
import LoadingElement from "@Common/components/LoadingElement";
import { primaryColors } from "@Common/tokens/colors";

import ArrowForwardRoundedIcon from "@mui/icons-material/ArrowForwardRounded";
import DeleteConfirmation from "@Common/components/DeleteConfirmation";
import { formatDate } from "@Strategy-planning/utils/dateHelper";
import { PATH_STRATEGY_PLANNING } from "@Common/tokens/routeTokens";
import HeaderTitle from "@Strategy-planning/components/HeaderTitle";

const orderedKey = (obj) => {
  if (!obj) {
    return [];
  }
  return Object.keys(obj).sort(function (a, b) {
    return obj[b].lastChanged - obj[a].lastChanged;
  });
};

const DetailsBox = ({ data, planId }) => {
  const navigate = useNavigate();
  const { lastChanged, title, complete, lastVisited } = data;
  const [confirm, setConfirm] = React.useState(false);
  const handleDialogClose = () => {
    setConfirm(false);
  };
  const handleDelete = async () => {
    const userPlanRef = doc(db, "userPlans", auth.currentUser.uid);
    const planRef = doc(db, "plan", planId);
    await deleteDoc(planRef);
    await updateDoc(userPlanRef, {
      [planId]: deleteField(),
    });
  };
  return (
    <>
      <Box sx={{ border: "1px solid #EAEAEA", p: 2, borderRadius: 1, mb: 1 }}>
        <Stack direction="row" spacing={1} alignItems="center">
          <Typography
            variant="h5"
            sx={{
              opacity: title ? 1 : 0.8,
              maxWidth: "40%",
              overflow: "scroll",
            }}
          >
            {title || "Untitled"}
          </Typography>
          <HeaderTitle
            variant={complete ? "complete" : "draft"}
            handleDelete={() => setConfirm(true)}
            showIcons={false}
          >
            {complete ? "Complete" : "Draft"}
          </HeaderTitle>
          <Box sx={{ flexGrow: 1 }} />
          <Box sx={{ display: { xs: "none", sm: "block" } }}>
            <Typography
              variant="subtitle1"
              sx={{ color: alpha(primaryColors.eggplant, 0.7) }}
            >
              Last edited: {formatDate({ date: lastChanged, withTime: true })}
            </Typography>
          </Box>
          <IconButton
            onClick={() =>
              complete
                ? navigate(`/${PATH_STRATEGY_PLANNING}/${planId}/plan`)
                : navigate(
                    `/${PATH_STRATEGY_PLANNING}/${planId}/${
                      lastVisited || "details"
                    }`
                  )
            }
          >
            <ArrowForwardRoundedIcon />
          </IconButton>
        </Stack>
      </Box>
      <DeleteConfirmation
        open={confirm}
        title="Are you sure you want to delete this Plan?"
        content={title || "Untitled"}
        handleClose={handleDialogClose}
        handleDelete={handleDelete}
      />
    </>
  );
};

const Dashboard = () => {
  const { enqueueSnackbar } = useSnackbar();
  const [plans, setPlans] = React.useState(null);
  const [loading, setLoading] = React.useState("Loading...");
  const navigate = useNavigate();
  const { pathname } = useLocation();

  React.useEffect(() => {
    document.title = "Dashboard - strategy planning tool";
    storeLastLocation(pathname);
    if (!getUserToken()) {
      navigate(-1);
      return;
    }
    const getPlans = async () => {
      const userDataRef = doc(db, "userPlans", getUserToken());
      try {
        setLoading("Fetching plans...");
        const docSnap = await getDoc(userDataRef);
        if (!docSnap.exists()) {
          await setDoc(userDataRef, {});
        }
        onSnapshot(userDataRef, (doc) => {
          const data = doc.data();
          setPlans(data);
          setLoading("");
        });
      } catch (error) {
        console.log(error);
        userSignOut();
        navigate("/");
      }
    };
    getPlans();
  }, [navigate, pathname]);

  const newPlan = async () => {
    setLoading("Creating new plan");
    const userDataRef = doc(db, "userPlans", auth.currentUser.uid);
    const planCollectionRef = collection(db, "plan");
    const newPlan = await addDoc(planCollectionRef, defaultInitialValues);
    await setDoc(userDataRef, {
      ...plans,
      [newPlan.id]: { lastChanged: new Date() },
    });
    navigate(`/${PATH_STRATEGY_PLANNING}/${newPlan.id}/details`);
  };

  const handleSignOut = () => {
    userSignOut().then(() => {
      enqueueSnackbar("Logged out successfully", {
        variant: "success",
      });
      navigate("/");
    });
  };

  if (loading) return <LoadingElement>{loading}</LoadingElement>;
  return (
    <>
      <Header
        rightContent={
          <Button color="eggplant" variant="outlined" onClick={handleSignOut}>
            Logout
          </Button>
        }
      />
      <Container maxWidth="md">
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Typography variant="h5">Your plans</Typography>
          <Button variant="outlined" color="eggplant" onClick={newPlan}>
            Create new plan
          </Button>
        </Stack>
        <Divider sx={{ mt: 3, mb: 4 }} />
        {orderedKey(plans).map((planId, index) => {
          return (
            <DetailsBox key={index} planId={planId} data={plans[planId]} />
          );
        })}
      </Container>
    </>
  );
};

export default Dashboard;
