import {
  PATH_KPI_LIBRARY_GENERATOR,
  PATH_STRATEGY_PLANNING,
} from "@Common/tokens/routeTokens";
import BlogKPIGenerator from "@KPI-generator/BlogKPIGenerator";
import { CssBaseline } from "@mui/material";
import StrategyPlanningRoutes from "@Strategy-planning/Routes";
import KPILibraryGenerator from "app/KPI-library-generator";
import React from "react";
import { Route, Routes, Navigate } from "react-router-dom";

function App() {
  return (
    <>
      <CssBaseline />
      <Routes>
        <Route
          path="/"
          element={<Navigate replace to={`/${PATH_STRATEGY_PLANNING}`} />}
        />
        <Route
          path={`/${PATH_STRATEGY_PLANNING}/*`}
          element={<StrategyPlanningRoutes />}
        />
        <Route
          path={`/${PATH_KPI_LIBRARY_GENERATOR}`}
          element={<KPILibraryGenerator />}
        />
        <Route
          path={`/${PATH_KPI_LIBRARY_GENERATOR}/blog`}
          element={<BlogKPIGenerator />}
        />
        {/* testing */}
        {/* <Route path="/test" element={<Try />} /> */}
      </Routes>
    </>
  );
}

export default App;
