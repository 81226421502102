import LoadingElement from "@Common/components/LoadingElement";
import { db } from "@Common/firebase";
import { getAnonymousToken } from "@Common/storage/anonymousTokenStorage";
import { getUserToken } from "@Common/storage/authTokenStorage";
import { doc, getDoc, onSnapshot } from "firebase/firestore";
import { useSnackbar } from "notistack";
import React from "react";
import {
  Navigate,
  Route,
  Routes,
  useLocation,
  useNavigate,
  useParams,
} from "react-router-dom";
import { defaultInitialValues } from "./data/defaults";
import Details from "./pages/Details";
import FocusArea from "./pages/FocusArea";
import KPIs from "./pages/KPIs";
import Objectives from "./pages/Objectives";
import Plan from "./pages/Plan";
import Projects from "./pages/Project";
import Values from "./pages/Values";
import Vision from "./pages/Vision";
import { storeLastLocation } from "./storage/lastLocation";
import { SPT_PATHS, SPT_PATH_ORDER } from "./tokens/strategyPlanningPaths";

const PlanRoutes = () => {
  const { pathname } = useLocation();
  React.useEffect(() => {
    const currentPath = pathname.split("/").pop();
    document.title =
      SPT_PATH_ORDER.find((o) => o.fullPath.split("/").pop() === currentPath)
        .title + " - strategy planning tool";
  }, [pathname]);
  const { planId } = useParams();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();

  const [initialValues, setInitialValues] = React.useState(null);
  const [loading, setLoading] = React.useState("Loading plan");

  React.useEffect(() => {
    storeLastLocation(pathname);
    const getDataForPlan = () => {
      setLoading("Fetching plan");
      const planRef = doc(db, "plan", planId);

      getDoc(planRef).then((planSnap) => {
        if (planSnap.exists()) {
          enqueueSnackbar("Plan loaded successfully", { variant: "success" });
          // const docOnSnap = await onSnapshot(userDataRef);
          onSnapshot(planRef, (doc) => {
            const data = doc.data();
            data?.focusAreas?.forEach((focusArea) => {
              focusArea.objectives.forEach((objective) => {
                objective.startDate = objective?.startDate
                  ? new Date(objective?.startDate)
                  : null;
                objective.endDate = objective?.endDate
                  ? new Date(objective?.endDate)
                  : null;
                objective.projects.forEach((project) => {
                  project.startDate = project?.startDate
                    ? new Date(project?.startDate)
                    : null;
                  project.endDate = project?.endDate
                    ? new Date(project?.endDate)
                    : null;
                });
                objective.kpis.forEach((kpi) => {
                  kpi.startDate = kpi?.startDate
                    ? new Date(kpi?.startDate)
                    : null;
                  kpi.endDate = kpi?.endDate ? new Date(kpi?.endDate) : null;
                });
              });
            });
            setInitialValues(data);
            setLoading("");
          });
        } else {
          setInitialValues(defaultInitialValues);
          setLoading("");
        }
      });
    };
    setLoading("Authenticating user");
    if (getUserToken()) {
      const userDataRef = doc(db, "userPlans", getUserToken());
      getDoc(userDataRef).then((docSnap) => {
        if (docSnap.exists() && planId in docSnap.data()) {
          // const docOnSnap = await onSnapshot(userDataRef);
          getDataForPlan();
        } else {
          enqueueSnackbar("Not authorized!", { variant: "error" });
          enqueueSnackbar("Redirecting to dashboard", { variant: "info" });
          navigate("/", { replace: true });
        }
      });
    } else {
      if (getAnonymousToken() !== planId) {
        enqueueSnackbar("Not authorized!", { variant: "error" });
        navigate("/", { replace: true });
      } else {
        getDataForPlan();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  // console.log(initialValues);
  if (loading) return <LoadingElement>{loading}</LoadingElement>;
  return (
    <Routes>
      <Route
        path="/*"
        element={<Navigate replace to={"/strategy-planning/"} />}
      />
      <Route
        path={`${SPT_PATHS.DETAILS}`}
        element={<Details initialValues={initialValues} />}
      />
      <Route
        path={`${SPT_PATHS.VISION}`}
        element={<Vision initialValues={initialValues} />}
      />
      <Route
        path={`${SPT_PATHS.VALUES}`}
        element={<Values initialValues={initialValues} />}
      />
      <Route
        path={`${SPT_PATHS.FOCUS_AREA}`}
        element={<FocusArea initialValues={initialValues} />}
      />
      <Route
        path={`${SPT_PATHS.OBJECTVIES}`}
        element={<Objectives initialValues={initialValues} />}
      />
      <Route
        path={`${SPT_PATHS.PROJECT}`}
        element={<Projects initialValues={initialValues} />}
      />
      <Route
        path={`${SPT_PATHS.KPIS}`}
        element={<KPIs initialValues={initialValues} />}
      />
      <Route
        path={`${SPT_PATHS.PLAN}`}
        element={<Plan initialValues={initialValues} />}
      />
    </Routes>
  );
};

export default PlanRoutes;
